import { phdScannerApiAxios } from "../lib/axios-interceptor";
import { handleResponse } from "../utils/handleResponse";

export const ContactMessage = {

  async sendMessage(reqData: any) {
    return handleResponse(() =>
      phdScannerApiAxios.post("/api/contact", reqData),
    );
  },
};
