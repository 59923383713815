import React, { useState } from "react";
import {
  AlertCircle,
  CheckCircle,
  Send,
  Calendar,
  Globe,
  Users,
  Link,
  User,
} from "lucide-react";
import { useMutations } from "../../hooks/useMutations";
import { OpportunityService } from "../../services/OpportunityService";
import { useSnackBar } from "../../hooks/useSnackBar";

const Popup = ({ message, isSuccess, onClose }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${!message ? "hidden" : ""}`}
    >
      <div
        className={`bg-white p-6 rounded-lg shadow-lg ${isSuccess ? "border-teal-500" : "border-red-500"} border-2 max-w-md w-full mx-4`}
      >
        <div className="flex items-center mb-4">
          {isSuccess ? (
            <CheckCircle className="h-6 w-6 text-teal-500 mr-2" />
          ) : (
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
          )}
          <h3
            className={`text-lg font-semibold ${isSuccess ? "text-teal-700" : "text-red-700"}`}
          >
            {isSuccess ? "Success" : "Error"}
          </h3>
        </div>
        <p className="text-gray-600 mb-4">{message}</p>
        <button
          className="w-full px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 transition duration-300"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export const OpportunityForm = () => {
  const initialFormData = {
    category: "featured",
    title: "",
    department: "",
    university: "",
    university_url: "",
    city: "",
    country: "",
    closing_date: "",
    open_to: "",
    supervisor: "",
    supervisor_url: "",
    type: "",
    fully_funded: "false",
    opportunity_url: "",
    description: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const enqMsg = useSnackBar();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const { mutateAsync: saveOpportunity, isLoading: saving } = useMutations(
    OpportunityService.createOpportunity
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await saveOpportunity({
        ...formData,
        fully_funded: formData?.fully_funded === "true",
      });

      if (res?.error) {
        throw new Error(res?.error);
      } else {
        enqMsg(res?.message, "success");
      }
    } catch (error) {
      enqMsg(error);
    }
  };

  const inputStyle =
    "w-full rounded-md border border-teal-300 py-2 px-3 shadow-sm focus:border-teal-500 focus:ring focus:ring-teal-200 focus:ring-opacity-50 transition duration-300";
  const labelStyle = "block text-sm font-medium text-teal-700 mb-1";

  const formFields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      required: true,
      placeholder: "Enter the opportunity title",
      icon: <User className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "university",
      label: "University Name",
      type: "text",
      required: true,
      placeholder: "Enter the university name",
      icon: <Globe className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "university_url",
      label: "University Website",
      type: "url",
      required: true,
      placeholder: "https://www.university.edu",
      icon: <Link className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "department",
      label: "Department",
      type: "text",
      required: true,
      placeholder: "Enter the department name",
      icon: <Users className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
      icon: <Globe className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "country",
      label: "Country",
      type: "text",
      required: true,
      icon: <Globe className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "closing_date",
      label: "Closing Date",
      type: "date",
      required: true,
      icon: <Calendar className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "open_to",
      label: "Open To",
      type: "text",
      required: true,
      placeholder: "e.g., All nationalities, EU citizens only",
      icon: <Users className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "opportunity_url",
      label: "Opportunity URL",
      type: "url",
      required: false,
      placeholder: "https://www.university.edu/position/12345 (optional)",
      icon: <Link className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "supervisor",
      label: "Supervisor",
      type: "text",
      required: false,
      placeholder: "Enter supervisor name (optional)",
      icon: <User className="h-5 w-5 text-teal-500" />,
    },
    {
      name: "supervisor_url",
      label: "Supervisor URL",
      type: "url",
      required: false,
      placeholder:
        "https://www.university.edu/supervisor or mailto:test@university.uk (optional)",
      icon: <Link className="h-5 w-5 text-teal-500" />,
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="bg-teal-600 py-6 px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-white text-center">
            Post PhD Opportunity
          </h2>
        </div>
        <div className="p-6 sm:p-8">
          <p className="text-teal-600 text-center mb-8">
            Share your PhD opportunity with potential candidates.
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {formFields.slice(0, 2).map((field) => (
                <div key={field.name}>
                  <label htmlFor={field.name} className={labelStyle}>
                    {field.label}
                    {field.required && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      {field.icon}
                    </div>
                    <input
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      className={`${inputStyle} pl-10`}
                      required={field.required}
                      placeholder={field.placeholder || field.label}
                    />
                  </div>
                </div>
              ))}
            </div>

            {formFields.slice(2).map((field, index) => (
              <div
                key={field.name}
                className={
                  index === 1 ? "grid grid-cols-1 sm:grid-cols-2 gap-6" : ""
                }
              >
                <div className={index === 1 ? "" : "mb-4"}>
                  <label htmlFor={field.name} className={labelStyle}>
                    {field.label}
                    {field.required && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      {field.icon}
                    </div>
                    <input
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      className={`${inputStyle} pl-10`}
                      required={field.required}
                      placeholder={field.placeholder || field.label}
                    />
                  </div>
                </div>
                {index === 1 && (
                  <div>
                    <label htmlFor="fully_funded" className={labelStyle}>
                      Fully Funded
                    </label>
                    <select
                      id="fully_funded"
                      name="fully_funded"
                      value={formData.fully_funded}
                      onChange={handleChange}
                      className={inputStyle}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                )}
              </div>
            ))}

            <div>
              <label htmlFor="description" className={labelStyle}>
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className={`${inputStyle} h-32`}
                placeholder="Describe the opportunity details (optional)"
              />
            </div>

            <p className="text-sm text-teal-500 mt-2">
              <span className="text-red-500">*</span> Required fields
            </p>

            <button
              type="submit"
              className="w-full bg-teal-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 transition duration-300 flex items-center justify-center"
            >
              <Send className="h-5 w-5 mr-2" />
              Submit Opportunity
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
