import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, LogOut, User, Mail, Settings } from "lucide-react";

const UserProfilePopup = ({ user, handleLogout }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const ProfileButton = ({ icon: Icon, label, onClick, className = "" }) => (
    <button
      onClick={onClick}
      className={`w-full text-left px-4 py-3 hover:bg-gray-100 rounded-md transition duration-300 flex items-center space-x-3 ${className}`}
    >
      <Icon size={18} className="text-gray-500" />
      <span className="font-medium">{label}</span>
    </button>
  );

  return (
    <div className="relative" ref={popupRef}>
      <button
        onClick={() => setPopupVisible(!isPopupVisible)}
        className="flex items-center space-x-3 bg-white border border-gray-300 rounded-full pl-2 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 hover:bg-gray-50 shadow-sm"
      >
        <img
          src={user.picture || "https://via.placeholder.com/40"}
          alt={user.name}
          className="w-10 h-10 rounded-full object-cover border-2 border-blue-200"
        />
        <span className="font-medium text-gray-700 hidden sm:inline">
          {user.name}
        </span>
        <ChevronDown size={16} className="text-gray-500 hidden sm:inline" />
      </button>

      {isPopupVisible && (
        <div className="absolute -right-10 sm:right-0 mt-3 w-screen max-w-[90vw] sm:w-80 bg-white border border-gray-200 shadow-2xl rounded-lg overflow-hidden z-20 transform transition-all duration-300 ease-out opacity-100 scale-100">
          <div className="p-6 border-b border-gray-200 bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-4">
              <img
                src={user.picture || "https://via.placeholder.com/80"}
                alt={user.name}
                className="w-20 h-20 rounded-full object-cover border-4 border-white shadow-lg"
              />
              <div className="text-center sm:text-left">
                <p className="text-gray-800 font-bold text-xl mb-1">
                  {user.name}
                </p>
                <p className="text-gray-500 text-sm">{user.email}</p>
              </div>
            </div>
          </div>

          {/*<div className="p-3 space-y-1">*/}
          {/*    <ProfileButton icon={User} label="Profile" onClick={() => {}} />*/}
          {/*    <ProfileButton icon={Mail} label="Messages" onClick={() => {}} />*/}
          {/*    <ProfileButton icon={Settings} label="Settings" onClick={() => {}} />*/}
          {/*</div>*/}

          <div className="p-3 border-t border-gray-200 bg-gray-50">
            <ProfileButton
              icon={LogOut}
              label="Logout"
              onClick={handleLogout}
              className="text-red-500 hover:bg-red-50 font-semibold"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfilePopup;
