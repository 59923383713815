import { useSnackbar as useSnackbarOriginal } from "notistack";
import React from "react";

export const useSnackBar = () => {
  const { enqueueSnackbar } = useSnackbarOriginal();
  const enqueueMessage = (
    message: string,
    variant: "error" | "default" | "success" | "warning" | "info" | undefined,
  ) => {
    enqueueSnackbar(message, { variant });
  };

  return enqueueMessage;
};
