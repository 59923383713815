import { phdScannerApiAxios } from "../lib/axios-interceptor";
import { handleResponse } from "../utils/handleResponse";

export const AuthService = {
  async getUserDetails() {
    return handleResponse(() => phdScannerApiAxios.get("/api/user"));
  },

  async logout() {
    return handleResponse(() => phdScannerApiAxios.post("/api/logout", {}));
  },

  async login() {
    return handleResponse(() => phdScannerApiAxios.post("/api/login", {}));
  },  

  async subscribeMail(reqData: { email: string }) {
    return handleResponse(() => 
      phdScannerApiAxios.post("/api/subscribe/email", reqData),
    );
  },
};
