import { useLocation, useNavigate } from "react-router-dom";

export const useGetParams = (): {
  qsparams: Record<string, unknown>;
  setQueryParams: (
      newParams: Record<string, string | object>,
      replace: { replace?: boolean },
      clearAll?: boolean,
  ) => void;
} => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the existing query parameters into an object
  const params = Object.fromEntries(
      new URLSearchParams(location.search).entries(),
  );

  // Function to update query parameters
  const setQueryParams = (
      newParams: Record<string, string | object>,
      { replace }: { replace?: boolean },
      clearAll?: boolean,
  ) => {
    console.log({ replace });

    const searchParams = clearAll
        ? new URLSearchParams()
        : new URLSearchParams(location.search);

    // Update the search parameters with newParams
    Object.entries(newParams).forEach(([key, value]) => {
      // Check if the value is an object and stringify it
      if (typeof value === 'object') {
        searchParams.set(key, JSON.stringify(value));
      } else {
        searchParams.set(key, value);
      }
    });

    // Use navigate to update the URL
    navigate(`?${searchParams.toString()}`, { replace });
  };

  return { qsparams: params, setQueryParams };
};
