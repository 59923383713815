import React, { useState, useEffect } from "react";
import {
  FaBuilding,
  FaUser,
  FaCalendarAlt,
  FaCheck,
  FaTrash,
} from "react-icons/fa";
import useAnalytics from '../../hooks/useAnalytics'; 

const OpportunityCard = ({ opportunity, handleApprove, handleDelete }) => {
  const [imgError, setImgError] = useState(false);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  useEffect(() => {
    const img = new Image();
    img.src = `./university/${opportunity.university}.png`;
    img.onload = () => setImgError(false);
    img.onerror = () => setImgError(true);
  }, [opportunity.university]);

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-xl">
      <div className="p-6 flex flex-col h-full">
        <div className="flex items-center justify-between mb-4">
          <div className="flex-shrink-0 w-20 h-20 mr-4">
            {!imgError ? (
              <img
                src={`./university/${opportunity.university}.png`}
                alt={opportunity.university}
                className="w-full h-full object-cover rounded-lg border border-gray-300"
              />
            ) : (
              <a
                href={`https://${opportunity.university_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-indigo-800 font-semibold text-sm md:text-base flex items-center justify-center w-full h-full rounded-md border border-gray-300 p-2"
              >
                {opportunity.university}
              </a>
            )}
          </div>
          {opportunity.open_to && (
            <span className="px-3 py-1 bg-indigo-100 text-indigo-800 rounded-full text-xs md:text-sm font-medium">
              {opportunity.open_to}
            </span>
          )}
        </div>
        <h3 className="text-xl font-semibold text-gray-800 mb-3">
          {opportunity.title}
        </h3>
        <div className="space-y-2 text-sm text-gray-600 flex-grow">
          {[
            {
              label: "Department",
              value: opportunity.department,
              icon: <FaBuilding className="text-green-500" />,
            },
            {
              label: "Supervisor",
              value: opportunity.supervisor,
              url: opportunity.supervisor_url,
              icon: <FaUser className="text-blue-500" />,
            },
            {
              label: "Deadline",
              value: opportunity.closing_date,
              icon: <FaCalendarAlt className="text-red-500" />,
            },
          ].map(
            (item, index) =>
              item.value && (
                <p key={index} className="flex items-center">
                  <span className="mr-2">{item.icon}</span>
                  {item.url ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-600 hover:text-indigo-800 underline"
                    >
                      {item.value}
                    </a>
                  ) : (
                    <span>{item.value}</span>
                  )}
                </p>
              ),
          )}
        </div>
        <div className="flex justify-end mt-4 space-x-2">
          {opportunity.status === "unapproved" && (
            <button
              onClick={() => handleApprove(opportunity.id)}
              className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded transition duration-150 ease-in-out flex items-center text-sm"
            >
              <FaCheck className="mr-2" /> Approve
            </button>
          )}
          <button
            onClick={() => handleDelete(opportunity.id)}
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-150 ease-in-out flex items-center text-sm"
          >
            <FaTrash className="mr-2" /> Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpportunityCard;