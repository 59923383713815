import { Nil } from "../globalTypes";

export const noop = () => {};

export const createUrl = (
  url: string,
  params: Nil<Record<string, unknown>> = {},
) => {
  const queryParams = transformParams(params);
  if (queryParams) return `${url}?${queryParams}`;
  return url;
};

export function transformParams(
  query: Nil<Record<string, unknown>> = {},
): string | null {
  if (!query) {
    return null;
  }

  const params = Object.keys(query)
    .filter((param) => query[param] !== undefined) // Filter out undefined and null values
    .map((param) => {
      let value = query[param];
      if (typeof value === "object") value = JSON.stringify(value);
      return `${encodeURIComponent(param)}=${encodeURIComponent(
        value as string,
      )}`;
    });

  return params.length > 0 ? params.join("&") : null;
}
