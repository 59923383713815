import React, { useState } from "react";
import { MapPin, Phone, Mail, Clock, Send, X } from "lucide-react";
import { ContactMessage } from "../../services/ContactMessage";
import useAnalytics from '../../hooks/useAnalytics';
import SEOMetadata from "../SEOMetadata/SEOMetadata";

const ContactUs = () => {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
    const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [errors, setErrors] = useState({});
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name.trim()) formErrors.name = "Name is required";
        if (!formData.email.trim()) formErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = "Email is invalid";
        if (!formData.message.trim()) formErrors.message = "Message is required";
        else if (formData.message.length > 1500) formErrors.message = "Message is too long";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true); // Start loading
            try {
                trackEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Contact Us'
                });
                await ContactMessage.sendMessage(formData); // Call the API
                setFormData({ name: "", email: "", message: "" }); // Reset form
                setIsPopupVisible(true); // Show success popup
            } catch (error) {
                console.error("Error sending message:", error);
                // Optionally handle error state here
            } finally {
                setIsLoading(false); // Stop loading
            }

        }
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    const ContactInfo = ({ icon: Icon, title, content }) => (
        <div className="flex items-start mb-6">
            <div className="mr-3 mt-1 flex-shrink-0">
                <Icon className="h-5 w-5 text-[#DB4F40]" />
            </div>
            <div>
                <h3 className="text-base font-medium text-[#1B222A]">{title}</h3>
                <p className="text-sm text-[#1B222A] opacity-80">{content}</p>
            </div>
        </div>
    );

    return (
        <div className="bg-[#FFEEED]">
            <SEOMetadata
                title="PhDScanner Contact Us Page"
                description="Get in Touch. We'd love to hear from you. Send us a message and we'll respond as soon as possible"
                keywords="phdscanner, phd, phd postions, phd opportunities, phd openings, academic researchers, higher education, phd vacancies, phd jobs"
                imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
                url="https://www.phdscanner.com/contact"
            />
            
            <section 
                className="relative w-full px-2 min-h-screen flex items-center justify-center overflow-hidden"
                style={{ background: `linear-gradient(135deg, #EEA0ED 0%, #5AC1E4 100%)` }}
            >
                <div className="container mx-auto px-4 py-16 relative z-10">
                    <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
                        <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8 lg:py-16">
                            <div className="mb-8 text-center">
                                <h2 className="text-4xl font-extrabold text-[#1B222A] leading-tight mb-4">
                                    Get in <span className="text-[#DB4F40]">Touch</span>
                                </h2>
                                <p className="text-xl text-[#1B222A] max-w-2xl mx-auto mb-8 leading-relaxed">
                                    We'd love to hear from you. Send us a message and we'll respond as soon as possible.
                                </p>
                            </div>

                            <div className="mt-12 grid gap-8 md:grid-cols-2">
                                <div className="bg-[#EEA0ED]/10 p-8 rounded-xl">
                                    <h3 className="text-2xl font-bold mb-6 text-[#1B222A]">Contact Information</h3>
                                    <ContactInfo icon={MapPin} title="Our Address" content="phdscanner, Richmond Rd, Bradford BD7 1DP, UK" />
                                    <ContactInfo icon={Phone} title="Phone" content="+44 (789) 989-8897" />
                                    <ContactInfo icon={Mail} title="Email" content="admin@phdscanner.com" />
                                    <ContactInfo icon={Clock} title="Working Hours" content="Mon-Fri: 08:00 - 17:00, Sat-Sun: 08:00 - 12:00" />
                                </div>

                                <div className="bg-white p-8 rounded-xl">
                                    <h3 className="text-2xl font-bold mb-6 text-[#1B222A]">Send us a message</h3>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium text-[#1B222A] mb-1">Your name</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#DB4F40]"
                                            />
                                            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-[#1B222A] mb-1">Your email address</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#DB4F40]"
                                            />
                                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="message" className="block text-sm font-medium text-[#1B222A] mb-1">Your message</label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                value={formData.message}
                                 onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#DB4F40]"
                                                rows={4}
                                            />
                                            {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                                        </div>
                                        <button
                                            type="submit"
                                            className="w-full bg-[#DB4F40] hover:bg-[#C43B30] text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center"
                                        >
                                            <Send className="mr-2 h-4 w-4" /> Send Message
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isPopupVisible && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 px-4">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-xl font-semibold text-[#1B222A]">Message Sent</h3>
                                <button onClick={handleClosePopup} className="text-gray-500 hover:text-gray-700">
                                    <X className="h-6 w-6" />
                                </button>
                            </div>
                            <p className="text-[#1B222A] mb-4">
                                Thank you for getting in touch! We will get back to you shortly.
                            </p>
                            <button
                                onClick={handleClosePopup}
                                className="w-full bg-[#DB4F40] hover:bg-[#C43B30] text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default ContactUs;