import React, { useState, useEffect, useRef } from "react";
import {
  Building,
  User,
  ChevronLeft,
  ChevronRight,
  Eye,
  Trash2,
  Menu,
  X,
} from "lucide-react";
import OpportunityCard from "../OpportunityCard/OpportunityCard";

const AdminDashboard = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedSection, setSelectedSection] = useState("opportunities");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState("");
  const [statusFilter, setStatusFilter] = useState("unapproved");
  const [categoryFilter, setCategoryFilter] = useState("featured");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (sidebarRef.current && contentRef.current) {
        const windowHeight = window.innerHeight;
        const contentTop = contentRef.current.getBoundingClientRect().top;
        const contentHeight = windowHeight - contentTop;
        sidebarRef.current.style.height = `${contentHeight}px`;
        contentRef.current.style.minHeight = `${contentHeight}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (categoryFilter === "standard") {
      setStatusFilter("");
    }
  }, [categoryFilter]);

  useEffect(() => {
    if (selectedSection === "opportunities") {
      fetch(
        `https://www.phdscanner.com/api/admin/opportunities?status=${statusFilter}&category=${categoryFilter}`,
        { credentials: "include" },
      )
        .then((response) => response.json())
        .then((data) => setOpportunities(data));
    } else if (selectedSection === "messages") {
      fetch("https://www.phdscanner.com/api/admin/contact", { credentials: "include" })
        .then((response) => response.json())
        .then((data) => setMessages(data));
    }
  }, [selectedSection, statusFilter, categoryFilter]);

  const paginate = (items) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return items.slice(start, end);
  };

  const handleApprove = async (id) => {
    try {
      const response = await fetch(
        `https://www.phdscanner.com/api/opportunities/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            status: "approved",
          }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        setIsSuccess(false);
        throw new Error(errorData.error || "Failed to approve the opportunity");
      }

      const result = await response.json();
      setPopupMessage(result.message);
      setIsSuccess(true);
      setShowPopup(true);
      setOpportunities((prevOpportunities) =>
        prevOpportunities.filter((opportunity) => opportunity.id !== id),
      );
    } catch (error) {
      console.error("Error approving opportunity:", error.message);
    }
  };

  const handleOpportunityDelete = async (id) => {
    try {
      const response = await fetch(
        `https://www.phdscanner.com/api/opportunities?id=${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        setIsSuccess(false);
        throw new Error(errorData.error || "Failed to delete the opportunity");
      }

      const result = await response.json();
      setPopupMessage(result.message);
      setIsSuccess(true);
      setShowPopup(true);
      setOpportunities((prevOpportunities) =>
        prevOpportunities.filter((opportunity) => opportunity.id !== id),
      );
    } catch (error) {
      console.error("Error deleting opportunity:", error.message);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleDelete = (id) => {
    fetch(`https://www.phdscanner.com/api/contact?id=${id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setMessages(messages.filter((message) => message.id !== id));
        setDeleteResponse(data.message);
        setTimeout(() => setDeleteResponse(""), 3000);
      });
  };

  const Popup = ({ message, isSuccess, onClose }) => (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${!message ? "hidden" : ""}`}
    >
      <div
        className={`bg-white p-6 rounded-lg shadow-lg ${isSuccess ? "border-teal-500" : "border-red-500"} border-2 max-w-md w-full mx-4`}
      >
        <p
          className={`text-lg ${isSuccess ? "text-teal-600" : "text-red-600"} font-semibold mb-4`}
        >
          {message}
        </p>
        <button
          className="w-full px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 transition duration-300"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );

  const Sidebar = () => (
    <div
      ref={sidebarRef}
      className={`bg-teal-800 text-white flex flex-col fixed shadow-lg transition-all duration-300 ease-in-out overflow-y-auto ${sidebarOpen ? "w-64" : "w-0 -left-64"} md:w-64 md:left-0 z-20`}
    >
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-8 hidden md:block">
          Admin Dashboard
        </h2>
        <nav>
          <ul className="space-y-4">
            <li>
              <button
                onClick={() => {
                  setSelectedSection("opportunities");
                  setSidebarOpen(false);
                }}
                className={`flex items-center w-full py-2 px-4 rounded transition-colors duration-200 ${selectedSection === "opportunities" ? "bg-teal-700" : "hover:bg-teal-700"}`}
              >
                <Building className="mr-3" size={20} />
                <span>Opportunities</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setSelectedSection("messages");
                  setSidebarOpen(false);
                }}
                className={`flex items-center w-full py-2 px-4 rounded transition-colors duration-200 ${selectedSection === "messages" ? "bg-teal-700" : "hover:bg-teal-700"}`}
              >
                <User className="mr-3" size={20} />
                <span>Messages</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );

  const MessageCard = ({ message, onView, onDelete }) => (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <h4 className="font-semibold text-lg text-gray-800">{message.name}</h4>
      <p className="text-sm text-gray-500">{message.email_mentioned}</p>
      <p className="mt-4 text-gray-700">
        {message.message.substring(0, 100)}...
      </p>
      <p className="text-sm text-gray-400 mt-2">
        {new Date(message.created_at).toLocaleString()}
      </p>
      <div className="flex flex-col md:flex-row justify-end items-center mt-4 space-y-2 md:space-y-0 md:space-x-2">
        <button
          onClick={() => onView(message)}
          className="w-full md:w-auto px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700 transition-colors duration-200 flex items-center justify-center"
        >
          <Eye className="mr-2" size={16} /> View
        </button>
        <button
          onClick={() => onDelete(message.id)}
          className="w-full md:w-auto px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200 flex items-center justify-center"
        >
          <Trash2 className="mr-2" size={16} /> Delete
        </button>
      </div>
    </div>
  );

  const Pagination = ({ currentPage, totalItems, onPageChange }) => (
    <div className="flex flex-col md:flex-row justify-between items-center mt-6 space-y-4 md:space-y-0">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="w-full md:w-auto px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700 transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <ChevronLeft className="mr-2" size={16} /> Previous
      </button>
      <span className="text-gray-600">Page {currentPage}</span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage * itemsPerPage >= totalItems}
        className="w-full md:w-auto px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700 transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Next <ChevronRight className="ml-2" size={16} />
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col md:flex-row">
      <Sidebar />
      <div
        ref={contentRef}
        className="flex-1 transition-all duration-300 ease-in-out md:ml-64 overflow-y-auto"
      >
        <div className="p-4 md:p-8">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="p-2 bg-teal-600 text-white rounded-full hover:bg-teal-700 transition-colors duration-200 shadow-md md:hidden"
              aria-label={sidebarOpen ? "Close sidebar" : "Open sidebar"}
            >
              {sidebarOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
            <h1 className="text-2xl font-semibold text-gray-700">
              {selectedSection === "opportunities"
                ? "Manage Opportunities"
                : "View Messages"}
            </h1>
          </div>
          {selectedSection === "opportunities" && (
            <div>
              <div className="bg-white shadow rounded-lg p-4 md:p-6">
                <div className="flex flex-col md:flex-row gap-4 mb-4">
                  <div className="flex flex-col flex-grow">
                    <label
                      htmlFor="categoryFilter"
                      className="mb-1 text-gray-700"
                    >
                      Category
                    </label>
                    <select
                      id="categoryFilter"
                      className="border border-gray-300 rounded-md p-2 w-full"
                      value={categoryFilter}
                      onChange={(e) => setCategoryFilter(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      <option value="featured">Featured</option>
                      <option value="standard">Standard</option>
                    </select>
                  </div>
                  <div className="flex flex-col flex-grow">
                    <label
                      htmlFor="statusFilter"
                      className="mb-1 text-gray-700"
                    >
                      Status
                    </label>
                    <select
                      id="statusFilter"
                      className="border border-gray-300 rounded-md p-2 w-full"
                      value={statusFilter}
                      disabled={categoryFilter === "standard"}
                      onChange={(e) => setStatusFilter(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="approved">Approved</option>
                      <option value="unapproved">Unapproved</option>
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
                  {paginate(opportunities).map((opportunity) => (
                    <OpportunityCard
                      key={opportunity.id}
                      opportunity={opportunity}
                      handleApprove={handleApprove}
                      handleDelete={handleOpportunityDelete}
                    />
                  ))}
                </div>
                <Pagination
                  currentPage={currentPage}
                  totalItems={opportunities.length}
                  onPageChange={setCurrentPage}
                />
              </div>
            </div>
          )}

          {selectedSection === "messages" && (
            <div>
              <div className="bg-white shadow rounded-lg p-4 md:p-6">
                {messages.length > 0 ? (
                  <div className="space-y-4 md:space-y-6">
                    {paginate(messages).map((message) => (
                      <MessageCard
                        key={message.id}
                        message={message}
                        onView={setSelectedMessage}
                        onDelete={handleDelete}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">No messages to display.</p>
                )}
                <Pagination
                  currentPage={currentPage}
                  totalItems={messages.length}
                  onPageChange={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {selectedMessage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg overflow-y-auto max-h-full">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-semibold text-gray-800">
                {selectedMessage.name}
              </h4>
              <button
                onClick={() => setSelectedMessage(null)}
                className="text-gray-600 hover:text-gray-800"
              >
                Close
              </button>
            </div>
            <p className="text-gray-500">{selectedMessage.email_mentioned}</p>
            <p className="mt-4 text-gray-700">{selectedMessage.message}</p>
            <p className="text-sm text-gray-400 mt-2">
              {new Date(selectedMessage.created_at).toLocaleString()}
            </p>
          </div>
        </div>
      )}

      {deleteResponse && (
        <div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white p-4 text-center">
          {deleteResponse}
        </div>
      )}
      {showPopup && (
        <Popup
          message={popupMessage}
          isSuccess={isSuccess}
          onClose={closePopup}
        />
      )}
    </div>
  );
};

export default AdminDashboard;
