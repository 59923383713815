import React from 'react';
import phdscanner from "../../assets/phdscanner_logo.webp";
import { Mail, MapPin, ArrowUpRight } from 'lucide-react';
import useAnalytics from '../../hooks/useAnalytics';

const SocialLink = ({ href, children, label, trackEvent }) => (
    <a
        href={href}
        className="group flex items-center gap-2 block text-[#1B222A] hover:text-[#DB4F40] transition-colors duration-300"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackEvent({
            category: 'Social Link',
            action: 'Click',
            label: label
        })}
    >
        {children}
        <span className="text-sm font-semibold tracking-wide">{label}</span>
        <ArrowUpRight className="w-4 h-4 opacity-0 -translate-y-1 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300" />
    </a>
);

const FooterSection = ({ title, children }) => (
    <div className="space-y-6">
        <h3 className="text-xl font-bold tracking-wide text-gray-800">
            {title}
        </h3>
        {children}
    </div>
);
export default function Footer() {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
    const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

    return (
        <footer className="relative w-full bg-gradient-to-br from-[#EEA0ED] to-[#5AC1E4] py-16 font-poppins">
            {/* Subtle Pattern Background */}
            <div
                className="absolute inset-0 opacity-20 pointer-events-none"
                
            />

            <div className="relative z-10 max-w-7xl mx-auto px-6 md:px-8">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-12 mb-12">
                    {/* Logo Section */}
                    <div className="md:col-span-4 space-y-6">
                    <a href="/home" className="flex items-center">
              <img
                src={phdscanner}
                className="h-14 w-auto object-contain lg:h-14 h-10" // Responsive height
                alt="Logo"
              />
              <span
                className="ml-3 text-2xl lg:text-2xl text-xl font-bold" // Responsive text size
                style={{
                  fontFamily: 'Grocetask, sans-serif',
                  color: 'black'
                }}
              >
                PhDScanner
              </span>
            </a>
            <p 
                            className="text-[#1B222A] leading-relaxed text-lg font-light tracking-normal 
                            transform transition-transform hover:scale-[1.01] duration-300"
                            style={{ fontFamily: 'Grocetask, sans-serif' }}
                        >
                            Empowering researchers and academics with cutting-edge tools for success in their academic journey.
                        </p>
                    </div>

                    {/* Quick Links */}
                    <div className="md:col-span-3" >
                        <FooterSection 
                            title="Quick Links" 
                            style={{ fontFamily: 'Grocetask, sans-serif' }}
                        >
                            <nav className="space-y-4">
                                {Object.entries({
                                    About: '/about',
                                    Contact: '/contact',
                                    'Explore Opportunity': '/opportunity',
                                }).map(([item, url]) => (
                                    <a
                                        key={item}
                                        href={url}
                                        className="block text-[#1B222A] hover:text-[#DB4F40] 
                                        transition-all duration-300 text-sm font-semibold 
                                        tracking-wide transform hover:translate-x-2 hover:scale-105"
                                        style={{ fontFamily: 'Grocetask, sans-serif' }}
                                        onClick={() => trackEvent({
                                            category: 'Quick Link',
                                            action: 'Click',
                                            label: item
                                        })}
                                    >
                                        {item} →
                                    </a>
                                ))}
                            </nav>
                        </FooterSection>
                    </div>

                    {/* Contact Section */}
                    <div className="md:col-span-3">
                    <FooterSection title="Contact Us"  style={{ fontFamily: 'Grocetask, sans-serif' }}>
                            <div className="space-y-4 ">
                                <a
                                    href="mailto:admin@phdscanner.com"
                                    className="group flex items-center gap-2 text-gray-700 
                                    hover:text-indigo-700 transition-all duration-300 
                                    transform hover:scale-105"
                                    onClick={() => trackEvent({
                                        category: 'Contact',
                                        action: 'Email Click',
                                        label: 'admin@phdscanner.com'
                                    })}
                                >
                                    <Mail className="w-5 h-5"/>
                                    <span className="text-sm font-semibold tracking-wide">admin@phdscanner.com</span>
                                </a>
                                <div className="flex items-start gap-2 text-gray-700 
                                transform transition-transform hover:scale-[1.02] duration-300">
                                    <MapPin className="w-5 h-5 mt-1"/>
                                    <address className="not-italic text-sm tracking-wide leading-relaxed">
                                        PhDScanner<br/>
                                        Richmond Rd<br/>
                                        Bradford BD7 1DP<br/>
                                        UK
                                    </address>
                                </div>
                            </div>
                        </FooterSection>
                    </div>

                    {/* Social Links */}
                    <div className="md:col-span-2">
                    <FooterSection title="Connect"  style={{ fontFamily: 'Grocetask, sans-serif' }}>
                            <div className="space-y-4">
                                {/* Social links with hover effects */}
                                {[
                                    { 
                                        href: "https://www.linkedin.com/company/phdscanner", 
                                        label: "LinkedIn", 
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"/>
                                            </svg>
                                        )
                                    },
                                    { 
                                        href: "https://x.com/PhdScanner", 
                                        label: "Twitter", 
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                                            </svg>
                                        )
                                    },
                                    { 
                                        href: "https://www.facebook.com/Phdscanner", 
                                        label: "Facebook", 
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/>
                                            </svg>
                                        )
                                    },
                                    { 
                                        href: "https://www.instagram.com/phdscanner", 
                                        label: "Instagram", 
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                               <path d="M12 2.5c3.04 0 3.42 0 4.65.07 1.2.07 2.04.3 2.5.5.5.2 1.1.7 1.5 1.2.4.5.7 1.3.9 2.5.07 1.23.07 1.61.07 4.65s0 3.42-.07 4.65c-.2 1.2-.5 2.04-.9 2.5-.4.5-1 .8-2.5.9-1.23.07-1.61.07-4.65.07s-3.42 0-4.65 .07c-1.2-.2-2.04-.5-2.5-.9-.5-.4-.8-1-.9-2.5-.07-1.23-.07-1.61-.07-4.65s0-3.42.07-4.65c.2-1.2.5-2.04.9-2.5.4-.5 1-.8 2.5-.9 1.23-.07 1.61-.07 4.65-.07zm0 5.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 11a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm3.5-11.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                                            </svg>
                                        )
                                    },
                                    // Add other social links similarly
                                ].map((social) => (
                                    <SocialLink 
                                        key={social.label} 
                                        href={social.href} 
                                        label={social.label} 
                                        trackEvent={trackEvent}
                                    >
                                        {social.icon()}
                                    </SocialLink>
                                ))}
                            </div>
                        </FooterSection>
                    </div>
                </div>

                {/* Copyright Section */}
                <div className="pt-8 border-t border-[#1B222A]/20">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                        <p 
                            className="text-sm text-[#1B222A] tracking-wide 
                            transform transition-transform hover:scale-[1.02] duration-300"
                            style={{ fontFamily: 'Grocetask, sans-serif' }}
                        >
                            © 2024 PhDScanner. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>

            {/* Subtle Wave Divider */}
            <svg
                className="absolute bottom-0 left-0 right-0 text-[#FFEEED]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 120"
                preserveAspectRatio="none"
            >
                <path
                    fill="currentColor"
                    d="M0,0l80,13.3C160,27,320,53,480,61.3C640,69,800,59,960,53.3C1120,48,1280, 48,1360,48l80,0v72H0V0z"
                />
            </svg>
        </footer>
    );
}