import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { USER_LOGIN, USER_LOGOUT } from "./constants";
import { useQuery } from "../hooks/useQuery";
import { AuthService } from "../services/AuthService";
import { useMutations } from "../hooks/useMutations";
import { noop } from "../utils/helpers";

export const AuthContext = createContext({
  userDetails: null,
  isUserDetailsLoading: false,
  handleLogout: noop,
});

export const authReducer = (
  state: any,
  action: { type: any; payload?: any },
) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        userDetails: action.payload,
        isUserDetailsLoading: false,
      };

    case USER_LOGOUT:
      return { ...state, userDetails: null };
    default:
      return state;
  }
};

export const AuthContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, dispatch] = useReducer(authReducer, {
    userDetails: null,
    isUserDetailsLoading: true,
  });
  const { data, error, isLoading } = useQuery(
    [`userProfile`],
    AuthService.getUserDetails,
    {
      staleTime: 360_000,
      cacheTime: 360_000,
      enabled: !!state.userDetails, // Only run this query if userDetails is not null
    },
  );

  const { mutateAsync: logout } = useMutations(AuthService.logout);
  const handleLogout = useCallback(async () => {
    try {
      await logout();
      dispatch({
        type: USER_LOGOUT,
      });
    } catch (error) {
      console.error("logout failed");
    }
  }, []);
  useEffect(() => {
    dispatch({
      type: USER_LOGIN,
      payload: data?.hasOwnProperty("error") ? undefined : data,
    });
  }, [data]);

  return (
    <AuthContext.Provider value={{ ...state, dispatch, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
