import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FaFilter, FaTimes, FaHamburger } from "react-icons/fa";
import OpportunityGrid from "../OpportunityGrid/OpportunityGrid";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";
import { AuthContext } from "../../context/AuthContext";
import { useGetParams } from "../../hooks/useGetParams";
import { useMutations } from "../../hooks/useMutations";
import useAnalytics from '../../hooks/useAnalytics';
import SEOMetadata from "../SEOMetadata/SEOMetadata";

export const Opportunities = () => {
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID); // Initialize the analytics hook
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showSavedOnly, setShowSavedOnly] = useState(false);
  const [showLast24HourOnly, setShowLast24HourOnly] = useState(false);
  const [finalSavedOpportunities, setSavedOpportunities] = useState(new Set());
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showFullyFundedOnly, setShowFullyFundedOnly] = useState(false);
  const opportunitiesPerPage = 30;
  const sidebarRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const { qsparams, setQueryParams } = useGetParams();
  const { userDetails: user } = useContext(AuthContext);
  const { category = "standard" } = qsparams;

  const getLast24HoursTimestamp = () => {
    const now = Math.floor(Date.now() / 1000);
    return now - (24 * 60 * 60);
  };

  const params = useMemo(() => {
    const opportunityParams = {
      category: qsparams?.category || 'standard',
      country: qsparams?.country || undefined,
      university: qsparams?.university || undefined,
    };

    // Check for the created_at[gt] parameter in the URL
    const createdAtParam = qsparams['created_at[gt]'];

    if (createdAtParam) {
      // If the URL parameter exists, use it
      opportunityParams['created_at[gt]'] = createdAtParam;
    } else if (showLast24HourOnly) {
      // If the checkbox is checked and no URL parameter exists, use the timestamp
      const timestamp = getLast24HoursTimestamp();
      opportunityParams['created_at[gt]'] = timestamp;
    }

    return opportunityParams;
  }, [qsparams, showLast24HourOnly]);


  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const { data: opportunities = [], isLoading: loading } = useQuery(
    ["get all opportunities", params],
    OpportunityService.getAllOpportunities,
    params,
  );

  const { data: countries = [] } = useQuery(
    ["get all countries"],
    OpportunityService.getOpportunityCountries,
    { category: params?.category },
    { enabled: Boolean(params?.category) },
  );

  // Set selectedCountry based on URL parameter
  useEffect(() => {
    if (qsparams?.country) {
      const countryData = JSON.parse(qsparams.country);
      if (countryData.in && countryData.in.length > 0) {
        setSelectedCountry(countryData.in[0]); // Set the first country from the array
      }
    }
  }, [qsparams?.country]);

  const { data: universities = [] } = useQuery(
    ["get all universities", params?.country],
    OpportunityService.getOpportunityCountryUni,
    { category: params?.category, country: params?.country },
    { enabled: Boolean(params?.category && params?.country) },
  );

  // Set selectedUniversity based on URL parameter
  useEffect(() => {
    if (qsparams?.university) {
      const universityData = JSON.parse(qsparams.university);
      if (universityData.in && universityData.in.length > 0) {
        setSelectedUniversity(universityData.in[0]); // Set the first university from the array
      }
    }
  }, [qsparams?.university]);

  // Conditionally call getSavedOpportunities only if user is logged in
  const {
    data: savedOpportunities = [],
    error: savedOppError,
    isLoading: loadingSavedOpp,
    isSuccess: savedOppSuccess,
  } = useQuery(
    ["getSavedOpportunities", user?.id], // Include user ID in the query key
    () => OpportunityService.getSavedOpportunities({ userId: user.id }), // Pass user ID to the service
    {
      enabled: !!user, // Only run this query if user is logged in
    }
  );
  // Convert the fetched data to a Set
  useEffect(() => {
    // Safely extract the array from savedOpportunities object
    const opportunitiesArray = Array.isArray(savedOpportunities?.savedOpportunities)
      ? savedOpportunities.savedOpportunities
      : [];

    if (savedOppSuccess && opportunitiesArray.length > 0) {
      setSavedOpportunities(new Set(opportunitiesArray));
    }
  }, [savedOpportunities, savedOppSuccess]);


  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setSelectedUniversity("");

    // Track the country selection
    trackEvent({
      category: 'Filter',
      action: 'Select Country',
      label: country || 'None Selected'
    });

    // Prepare the base query parameters
    const baseParams = { category };

    // Only set the query parameters if a valid country is selected
    if (country && country !== "Select country") {
      const countryParam = JSON.stringify({ in: [country] });
      baseParams.country = countryParam;
    } else {
      // If "Select Country" is selected, remove the country param
      delete baseParams.country;
    }

    // Include created_at[gt] based on the checkbox state
    if (showLast24HourOnly) {
      const timestamp = getLast24HoursTimestamp();
      baseParams['created_at[gt]'] = timestamp;
    } else if (qsparams['created_at[gt]']) {
      // If created_at[gt] was in the URL, keep it
      baseParams['created_at[gt]'] = qsparams['created_at[gt]'];
    }

    // Set the query parameters
    setQueryParams(baseParams, { replace: true }, true);

    if (window.innerWidth < 1024) {
      setIsSidebarOpen(false);
    }
  };

  const handleUniversityChange = (event) => {
    const university = event.target.value;
    setSelectedUniversity(university);

    // Track the university selection
    trackEvent({
      category: 'Filter',
      action: 'Select University',
      label: university || 'None Selected'
    });

    // Prepare the base query parameters
    const baseParams = { category };

    // Only set the query parameters if a valid university is selected
    if (university && university !== "Select university") {
      const universityParam = JSON.stringify({ in: [university] });
      const countryParam = JSON.stringify({ in: [selectedCountry] });
      baseParams.university = universityParam;
      baseParams.country = countryParam;
    } else {
      // If "Select University" is selected, remove the university param
      const countryParam = JSON.stringify({ in: [selectedCountry] });
      baseParams.country = countryParam;
    }

    // Include created_at[gt] based on the checkbox state
    if (showLast24HourOnly) {
      const timestamp = getLast24HoursTimestamp();
      baseParams['created_at[gt]'] = timestamp;
    } else if (qsparams['created_at[gt]']) {
      // If created_at[gt] was in the URL, keep it
      baseParams['created_at[gt]'] = qsparams['created_at[gt]'];
    }

    // Set the query parameters
    setQueryParams(baseParams, { replace: true }, true);
    if (window.innerWidth < 1024) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        window.innerWidth < 1024
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOpportunities = opportunities.filter(
    (opportunity) =>
      (!showSavedOnly || finalSavedOpportunities?.has(opportunity.id)) &&
      (!showFullyFundedOnly || opportunity.fully_funded),
  );

  const totalPages = Math.ceil(
    filteredOpportunities.length / opportunitiesPerPage,
  );
  const currentOpportunities = filteredOpportunities.slice(
    (currentPage - 1) * opportunitiesPerPage,
    currentPage * opportunitiesPerPage,
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    trackEvent({
      category: 'Pagination',
      action: 'Change Page',
      label: `Page ${newPage}`
    });
    window.scrollTo({ top: 10, behavior: "smooth" });
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCheckboxChange = (setter, label) => {
    setter((prev) => !prev);
    trackEvent({
      category: 'Filter',
      action: 'Toggle Checkbox',
      label: label
    });
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  const toggleSave = (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    setSavedOpportunities((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(opportunityId)) {
        newSet.delete(opportunityId);
      } else {
        newSet.add(opportunityId);
      }
      return newSet;
    });
  };

  const { mutateAsync: createSaveOpportunity } = useMutations(
    OpportunityService.createSavedOpportunity
  );

  const { mutateAsync: deleteSaveOpportunity } = useMutations(
    OpportunityService.deleteSavedOpportunities
  );

  const handleToggleSave = async (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    try {
      if (finalSavedOpportunities.has(opportunityId)) {
        await deleteSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: 'Opportunity',
          action: 'UnSave',
          label: opportunityId
        });
      } else {
        await createSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: 'Opportunity',
          action: 'Save',
          label: opportunityId
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Effect to synchronize checkbox state with URL parameter
  useEffect(() => {
    const createdAtParam = qsparams['created_at[gt]'];
    if (createdAtParam) {
      setShowLast24HourOnly(true);
    } else {

      setShowLast24HourOnly(false);
    }
  }, [qsparams]);

  const handleLast24HourChange = () => {
    const newValue = !showLast24HourOnly; // Determine the new value
    setShowLast24HourOnly(newValue); // Update the state

    // Update the URL parameters based on the checkbox state
    if (newValue) {
      const timestamp = getLast24HoursTimestamp();
      setQueryParams({ ...qsparams, 'created_at[gt]': timestamp }, { replace: true }, true);
    } else {
      // If the checkbox is unchecked, remove the created_at[gt] parameter from the URL
      const { 'created_at[gt]': _, ...newParams } = qsparams; // Remove the created_at[gt] parameter
      setQueryParams(newParams, { replace: true }, true);
    }

    // Track the checkbox change event
    trackEvent({
      category: 'Filter',
      action: 'Toggle Checkbox',
      label: 'Published in Last 24 Hours',
      value: newValue ? 'Enabled' : 'Disabled'
    });
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 10);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <SEOMetadata
        title="PhDScanner Opportunity Page - PhD openings in United Kindom, Germany, Netherlands, Belgium, Denmark, Norway, Switerland, Sweden, Europe "
        description="PhDScanner Opportunity Page - Phd openings in United Kindom, Germany, Netherlands, Belgium, Denmark, Norway, Switerland, Sweden, Europe "
        keywords="phdscanner, phd, phd postions, phd opportunities, phd openings, academic researchers, phd jobs, phd vacancies"
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url="https://www.phdscanner.com/opportunity"
      />
      {/* Fixed Header */}
      <header
        className={`
          bg-gradient-to-br from-[#EEA0ED] to-[#5AC1E4]
          shadow-md 
          py-4 
          px-6 
          flex 
          items-center 
          border-b 
          border-gray-100 
          fixed 
          top-20
          left-0 
          right-0 
          z-40 
          backdrop-blur-sm 
          bg-opacity-90
          h-16 
          md:h-20
        `}
      >
        <div className="flex items-center justify-start w-full max-w-screen-xl mx-auto">
          <h1
            className={`
              text-xl 
              md:text-3xl 
              font-extrabold 
              text-[#1B222A] 
              tracking-tight 
              truncate
              text-left 
              w-full
            `}
          >
            {category === "featured"
              ? "Featured Opportunities"
              : "Explore Opportunities"}
          </h1>

          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className={`
              lg:hidden 
              bg-[#DB4F40] 
              text-white 
              p-2 
              md:p-3 
              rounded-full 
              hover:scale-105 
              transition-all 
              duration-300 
              shadow-md 
              hover:shadow-lg 
              active:scale-95 
              focus:outline-none 
              focus:ring-2 
              focus:ring-[#DB4F40]/50
              ml-4
            `}
            aria-label="Toggle Filters"
          >
            <FaFilter className="w-4 h-4 md:w-5 md:h-5" />
          </button>
        </div>
      </header>

      {/* Main Content Container with Flex and Scroll */}
      <div className="
          flex 
          flex-col 
          lg:flex-row 
          mt-16 
          md:mt-20 
          h-[calc(100vh-4rem)] 
          md:h-[calc(100vh-5rem)]
          relative
      ">
        {/* Fixed Sidebar */}
        <aside
  ref={sidebarRef}
          className={`
    w-80 
            bg-white 
            shadow-lg 
            p-6 
            transition-all 
            duration-300 
            ease-in-out 
    fixed 
    top-[4rem]  // Change from top-16 to top-[4rem]
    md:top-[5rem]  // Change from md:top-20 to md:top-[5rem]
    bottom-0 
    right-0 
   
            overflow-y-auto 
    ${isSidebarOpen 
      ? "translate-x-0 z-40" 
      : "translate-x-full"}  
    lg:translate-x-0 
    lg:static 
    lg:w-1/4 
            border-l 
            border-gray-100 
    pb-16  // Add extra padding at the bottom for mobile scrolling
          `}
        >


          {/* Filters Section with Enhanced Typography */}
          <div className="space-y-6">
            {/* Dropdown Sections with Modern Design */}
            {[
              {
                label: "Country",
                value: selectedCountry,
                onChange: handleCountryChange,
                options: countries,
                optionKey: "country",
                icon: "🌍"
              },
              {
                label: "University",
                value: selectedUniversity,
                onChange: handleUniversityChange,
                options: universities,
                optionKey: "university",
                disabled: !selectedCountry,
                icon: "🎓"
              }
            ].map((filter, index) => (
              <div key={index} className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-4 
            border-b-2 border-[#EEA0ED]/30 pb-2 
            font-['Poppins', 'sans-serif']">
                  <span className="mr-2">{filter.icon}</span>
                  {filter.label} Selection
                </h3>
                <div className="relative">
                  <select
                    className="w-full p-3 pr-10 border-2 border-[#EEA0ED]/30
              rounded-lg focus:outline-none focus:ring-2 
              focus:ring-[#DB4F40] text-[#1B222A]
              font-medium tracking-tight"
                    value={filter.value}
                    onChange={filter.onChange}
                    disabled={filter.disabled}
                  >
                    <option value="" className="text-gray-500">
                      Select {filter.label.toLowerCase()}
                    </option>
                    {filter.options.map((option) => (
                      <option
                        key={option[filter.optionKey]}
                        value={option[filter.optionKey]}
                        className="hover:bg-indigo-100"
                      >
                        {option[filter.optionKey]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-4 
          border-b-2 border-indigo-100 pb-2 
          font-['Poppins', 'sans-serif']">
                Quick Filters
              </h3>
              <div className="space-y-4">
                {[
                  {
                    label: "Last 24 Hours",
                    state: showLast24HourOnly,
                    onChange: handleLast24HourChange,
                    description: "Discover fresh opportunities"
                  },
                  {
                    label: "Saved Selections",
                    state: showSavedOnly,
                    onChange: () => handleCheckboxChange(setShowSavedOnly, 'Saved Selections'),
                    description: "Your curated list of interests"
                  },
                  {
                    label: "Fully Funded",
                    state: showFullyFundedOnly,
                    onChange: () => handleCheckboxChange(setShowFullyFundedOnly, 'Fully Funded'),
                    description: "Zero financial barriers"
                  }
                ].map((checkbox, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between 
              bg-[#EEA0ED]/10 p-3 rounded-lg hover:bg-[#EEA0ED]/20  
              transition duration-300 group"
                  >
                    <div className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        checked={checkbox.state}
                        onChange={checkbox.onChange}
                        className="form-checkbox h-5 w-5 text-[#DB4F40] 
      rounded-md focus:ring-2 focus:ring-[#DB4F40]"
                      />
                      <div>
                        <p className="text-sm font-semibold text-gray-800 
                    group-hover:text-indigo-600 transition">
                          {checkbox.label}
                        </p>
                        <p className="text-xs text-gray-500">
                          {checkbox.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>


          </div>
        </aside>

        <main
          id="main-content"
          className="
          lg:w-3/4 
            p-6 
            overflow-y-auto
          flex-1
          relative
          "
        >
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#DB4F40]"></div>
            </div>
          ) : currentOpportunities.length > 0 ? (
            <div className="flex flex-col h-full">
              <OpportunityGrid
                opportunities={currentOpportunities}
                user={user}
                savedOpportunities={finalSavedOpportunities}
                handleToggleSave={handleToggleSave}
              />

              {/* Pagination */}
              {opportunities?.length >= opportunitiesPerPage && (
                <div className="mt-auto flex justify-center py-4">
                  <nav className="inline-flex rounded-md shadow">
                    <button
                      onClick={() => {
                        handlePageChange(currentPage - 1);
                        // Scroll to top of the main content
                        const mainContent = document.getElementById('main-content');
                        if (mainContent) {
                          mainContent.scrollTop = 0;
                        }
                      }}
                      disabled={currentPage === 1}
                      className="px-4 py-2 rounded-l-md border border-[#EEA0ED]/30 bg-white text-sm font-medium text-[#1B222A] hover:bg-[#EEA0ED]/10 disabled:bg-gray-100 disabled:text-gray-400"
                    >
                      Previous
                    </button>
                    <span className="px-4 py-2 border-t border-b border-[#EEA0ED]/30 bg-white text-sm font-medium text-[#1B222A]">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      onClick={() => {
                        handlePageChange(currentPage + 1);
                        // Scroll to top of the main content
                        const mainContent = document.getElementById('main-content');
                        if (mainContent) {
                          mainContent.scrollTop = 0;
                        }
                      }}
                      disabled={currentPage === totalPages}
                      className="px-4 py-2 rounded-r-md border border-[#EEA0ED]/30 bg-white text-sm font-medium text-[#1B222A] hover:bg-[#EEA0ED]/10 disabled:bg-gray-100 disabled:text-gray-400"
                    >
                      Next
                    </button>
                  </nav>
                </div>
              )}
            </div>
          ) : (
            <p className="text-center text-gray-500 text-lg">
              No opportunities available. Please select a country and university.
            </p>
          )}
        </main>
      </div>
    </div>
  );
};
