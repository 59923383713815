// src/hooks/useAnalytics.ts
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

interface TrackEventParams {
  category: string;
  action: string;
  label?: string;
}

const useAnalytics = (GA_MEASUREMENT_ID: string) => {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }, [GA_MEASUREMENT_ID]);

  const trackPageView = (path: string) => {
    ReactGA.send({ hitType: 'pageview', page: path });
  };

  const trackEvent = ({ category, action, label }: TrackEventParams) => {
    ReactGA.event({ category, action, label });
  };

  return { trackPageView, trackEvent };
};

export default useAnalytics;