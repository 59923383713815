import { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useParams, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import useAnalytics from '../../hooks/useAnalytics'; // Import your analytics hook

import {
  ExclamationCircleIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  FaBuilding,
  FaUser,
  FaGraduationCap,
  FaCalendarAlt,
  FaHeart,
  FaRegHeart,
  FaExternalLinkAlt,
  FaMapPin,
} from "react-icons/fa";
import { useMutations } from "../../hooks/useMutations";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";

const IconWithTooltip = ({ icon: Icon, label, color, className, position }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Icon className={`${color} ${className || "h-5 w-5"}`} />
      </div>
      {showTooltip && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className={`absolute ${position === "left" ? "right-full" : "left-full"} ml-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10`}
        >
          {label}
        </motion.div>
      )}
    </div>
  );
};

function OpportunityDetail() {
  const { title } = useParams();
  const [opportunity, setOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imgError, setImgError] = useState(false);
  const [savedOpportunities, setSavedOpportunities] = useState(new Set());
  const imgRef = useRef(null);
  const location = useLocation();
  const { userDetails: user } = useContext(AuthContext);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID); // Initialize analytics


  // Fetch opportunity by ID
  const {
    data: getOpportunityById,
    error: getOpportunityByIdError,
    isLoading: getOpportunityByIdLoading,
    isSuccess: getOpportunityByIdSuccess,
  } = useQuery(
    ["getOpportunityById", title],
    () => OpportunityService.getOpportunityById(title),
    {
      enabled: !!title,
    }
  );

  // Add this useEffect at the beginning of your component
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [location.pathname]);


  // Modify the useEffect where opportunity data is handled
  // Handle opportunity data loading
  useEffect(() => {
    if (getOpportunityByIdLoading) {
      setLoading(true);
    } else if (getOpportunityByIdSuccess && getOpportunityById) {
      setOpportunity(getOpportunityById);
      // Generate and set the opportunity link
      const opportunityLink = OpportunityService.generateOpportunityLink(getOpportunityById);
      // Update the URL
      window.history.pushState({}, '', opportunityLink);
      // Track the event
      trackEvent({
        category: 'Opportunity',
        action: 'View Opportunity Details',
        label: `Opportunity ID: ${title}`
      });
      setLoading(false);
    } else if (getOpportunityByIdError) {
      setLoading(false);
    }
  }, [
    getOpportunityByIdLoading,
    getOpportunityByIdSuccess,
    getOpportunityById,
    getOpportunityByIdError,
    title
  ]);




  useEffect(() => {
    if (opportunity && opportunity.university) {
      const img = new Image();
      img.src = `/university/${opportunity.university}.png`;
      img.onload = () => setImgError(false);
      img.onerror = () => setImgError(true);
    }
  }, [opportunity]);


  const {
    data: getSavedOpportunities = [],
    error: savedOppError,
    isLoading: loadingSavedOpp,
    isSuccess: savedOppSuccess,
  } = useQuery(
    ["getSavedOpportunities", user?.id],
    () => OpportunityService.getSavedOpportunities({ userId: user.id }),
    {
      enabled: !!user, // Only run this query if user is logged in
    }
  );



  useEffect(() => {
    // Safely extract the array from savedOpportunities object
    const opportunitiesArray = Array.isArray(getSavedOpportunities?.savedOpportunities)
      ? getSavedOpportunities.savedOpportunities
      : [];

    if (savedOppSuccess && opportunitiesArray.length > 0) {
      setSavedOpportunities(new Set(opportunitiesArray));
    }
  }, [getSavedOpportunities, savedOppSuccess]);


  const { mutateAsync: createSaveOpportunity } = useMutations(
    OpportunityService.createSavedOpportunity
  );

  const { mutateAsync: deleteSaveOpportunity } = useMutations(
    OpportunityService.deleteSavedOpportunities
  );

  // Toggle save/unsave opportunities
  const toggleSave = (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    setSavedOpportunities((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(opportunityId)) {
        newSet.delete(opportunityId);
      } else {
        newSet.add(opportunityId);
      }
      return newSet;
    });
  };

  const handleApplyNowClick = () => {
    trackEvent({
      category: 'Opportunity',
      action: 'Apply Now Clicked',
      label: `Opportunity ID: ${opportunity.id}`
    });
  };


  const handleToggleSave = async (opportunityId) => {
    if (!user || !opportunity) return;

    try {
      if (savedOpportunities.has(opportunityId)) {
        await deleteSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: 'Opportunity',
          action: 'Unsave Opportunity',
          label: `Opportunity ID: ${opportunityId}`
        });
      } else {
        await createSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: 'Opportunity',
          action: 'Save Opportunity',
          label: `Opportunity ID: ${opportunityId}`
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const removeEmptyTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const removeEmpty = (node) => {
      for (let i = node.childNodes.length - 1; i >= 0; i--) {
        const child = node.childNodes[i];
        if (child.nodeType === 1) {
          removeEmpty(child);
          if (
            child.innerHTML.trim() === "" &&
            !["br", "hr"].includes(child.tagName.toLowerCase())
          ) {
            child.parentNode.removeChild(child);
          }
        } else if (child.nodeType === 3 && child.nodeValue.trim() === "") {
          child.parentNode.removeChild(child);
        }
      }
    };

    removeEmpty(doc.body);
    return doc.body.innerHTML;
  };


  // Error handling
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-br from-[#EEA0ED] to-[#5AC1E4]">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <div className="animate-spin mx-auto mb-4 h-16 w-16 border-t-4 border-b-4 border-sky-500 rounded-full"></div>
          <p className="text-xl font-semibold text-gray-700">
            Loading opportunity details...
          </p>
        </motion.div>
      </div>
    );
  }

  // Error Handling
  if (getOpportunityByIdError) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-50 to-indigo-50">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white border-l-4 border-red-500 text-red-700 p-4 sm:p-6 rounded-lg shadow-lg max-w-sm w-full"
          role="alert"
        >
          <div className="flex items-center">
            <ExclamationCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 mr-3 sm:mr-4" />
            <p className="font-bold text-sm sm:text-lg">
              An error occurred while fetching opportunity details.
            </p>
          </div>
        </motion.div>
      </div>
    );
  }

  if (!opportunity) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-sky-50 to-indigo-50">
        <div className="text-center">
          <ExclamationCircleIcon className="mx-auto h-16 w-16 text-red-500" />
          <p className="mt-4 text-xl font-semibold text-gray-700">
            No details available for this opportunity
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-sky-50 to-indigo-100 min-h-screen">
      <SEOMetadata
        title={`PhD Position: ${opportunity.title}`}
        description={`PhD opportunity at ${opportunity.university}, ${opportunity.country}`}
      />

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-[#EEA0ED] to-[#5AC1E4]">
        <div className="max-w-5xl mx-auto px-4 py-12 md:py-16">
          <div className="grid md:grid-cols-[auto_1fr] items-center gap-8">
            <div className="flex justify-center">
              {!imgError ? (
                <img
                  src={`/university/${opportunity.university}.png`}
                  alt={opportunity.university}
                  className="h-28 w-28 object-contain rounded-xl bg-white p-4 shadow-lg"
                  onError={() => setImgError(true)}
                />
              ) : (
                <FaGraduationCap className="h-28 w-28" />
              )}
            </div>
            <div>
              <h1 className="text-4xl md:text-5xl font-bold tracking-tight mb-3">
                {opportunity.title}
              </h1>
              <div className="flex items-center space-x-3 text-opacity-90">
                <FaGraduationCap className="h-6 w-6" />
                <span className="text-lg md:text-xl font-medium">
                  {opportunity.university} - {opportunity.country}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-5xl mx-auto px-4 py-8 md:py-12">
        <div className="bg-white rounded-2xl shadow-2xl p-6 md:p-10">
          {/* University Logo and Save Button Section */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 space-y-4 md:space-y-0">
  <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-6 w-full">
    {/* University Image Section */}
    <div className="md:w-2/5 flex-shrink-0">
      {!imgError ? (
        <img
          src={`/university/${opportunity.university}.png`}
          alt={opportunity.university}
          className="h-20 w-auto object-contain mb-4 md:mb-0"
          onError={() => setImgError(true)}
        />
      ) : (
        <FaGraduationCap className="h-20 w-20 text-gray-400 mb-4 md:mb-0" />
      )}
    </div>

    {/* University Name and Location Section */}
    <div className="md:w-2/5">
      <h2 className="text-2xl font-bold text-gray-800 mb-2 md:mb-0">
        {opportunity.university}
      </h2>
      <div className="flex items-center text-gray-600 space-x-2">
        <FaMapPin className="text-sky-600" />
        <span className="truncate">{opportunity.city}, {opportunity.country}</span>
      </div>
    </div>
  </div>

  {/* Save Button Section */}
  <div className="md:w-1/5 flex justify-center">
    <button
      onClick={() => user && handleToggleSave(`${opportunity.id}`)}
      className={`flex justify-center items-center h-12 w-12 rounded-full border border-rose-500 text-rose-500 hover:text-rose-700 transition-colors duration-200 ease-in-out ${!user ? "opacity-50 cursor-not-allowed" : ""}`}
      disabled={!user}
    >
      {savedOpportunities.has(`${opportunity.id}`) ? (
        <FaHeart className="h-6 w-6" />
      ) : (
        <FaRegHeart className="h-6 w-6" />
      )}
    </button>
  </div>
</div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            {opportunity.supervisor && (
              <DetailCard
                icon={FaUser}
                title="Supervisor"
                value={opportunity.supervisor}
                link={opportunity.supervisor_url}
                className="w-full"
              />
            )}
            {opportunity.closing_date > 0 && (
              <DetailCard
                icon={FaCalendarAlt}
                title="Deadline"
                value={formatDate(opportunity.closing_date)}
                className="w-full"
              />
            )}
            {opportunity.department && (
              <DetailCard
                icon={FaBuilding}
                title="Department"
                value={opportunity.department}
                className="w-full"
              />
            )}
          </div>

          {/* Badges */}
          <div className="flex space-x-4 mb-8">
            {opportunity.open_to && (
              <Badge
                text={opportunity.open_to}
                color="emerald"
                icon={InformationCircleIcon}
              />
            )}
            {opportunity.fully_funded && (
              <Badge
                text="Fully Funded"
                color="emerald"
                icon={CheckCircleIcon}
              />
            )}
          </div>

          {/* Description */}
          <div
            className="prose prose-sky max-w-none mb-10"
            dangerouslySetInnerHTML={{
              __html: removeEmptyTags(opportunity.description)
            }}
          />

          {/* Apply Button */}
          <div className="text-center">
            <a
              href={opportunity.opportunity_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleApplyNowClick}
              className="inline-flex items-center px-8 py-3 bg-[#DB4F40] text-white font-semibold rounded-full hover:bg-[#C64537] transition-all transform hover:scale-105"
            >
              Apply Now
              <ArrowTopRightOnSquareIcon className="ml-2 h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

// Helper Components
// Update DetailCard component
const DetailCard = ({ icon: Icon, title, value, link, className }) => (
  <div className={`bg-[#EEA0ED]/10 rounded-lg p-5 hover:shadow-md transition-shadow ${className}`}>
    <div className="flex items-center mb-3">
      <Icon className="h-6 w-6 text-[#DB4F40] mr-3" />
      <h3 className="text-lg font-semibold text-[#1B222A] truncate">{title}</h3>
    </div>
    {link ? (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#DB4F40] hover:underline truncate block"
      >
        {value}
      </a>
    ) : (
      <p className="text-[#1B222A]/70 truncate">{value}</p>
    )}
  </div>
);

// Update Badge component
const Badge = ({ text, color, icon: Icon }) => (
  <div className="inline-flex items-center bg-[#EEA0ED]/20 text-[#1B222A] px-4 py-1.5 rounded-full">
    {Icon && <Icon className="h-5 w-5 mr-2 text-[#DB4F40]" />}
    <span className="font-medium">{text}</span>
  </div>
);

const DetailItem = ({ icon: Icon, label, color, children }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="flex items-center space-x-3 p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300"
  >
    <IconWithTooltip
      icon={Icon}
      label={label}
      color={color}
      className="w-6 h-6 flex-shrink-0"
    />
    <div className="text-gray-700 text-sm sm:text-base">{children}</div>
  </motion.div>
);

const isValidClosingDate = (date) => {
  if (!date) return false;
  const numDate = Number(date);
  return !isNaN(numDate) && numDate > 0 && numDate !== Infinity;
};

const formatDate = (timestamp) => {
  const date = new Date(Number(timestamp) * 1000);
  const now = new Date();
  const diffTime = date - now;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return "Deadline passed";
  } else {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};

export default OpportunityDetail;
