import React from "react";
import { NavLink } from "react-router-dom";
import googleLogo from "../../assets/google.png";
import phdscanner from "../../assets/phdscanner_logo.webp";
import useAnalytics from '../../hooks/useAnalytics'; // Import the useAnalytics hook


export default function Login() {
  
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID); // Initialize the analytics hook

  const handleGoogleSignIn = () => {
    // Track the Google sign-in button click
    trackEvent({
      category: 'Button',
      action: 'Click',
      label: 'Google Sign In'
    });
    const googleSignInURL = `${process.env.REACT_APP_API_URL}/api/login`; // Use the environment variable
    window.location.href = googleSignInURL; // Redirect to the Google sign-in page
  };

  return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
        <div className="bg-white shadow-lg rounded-lg p-8 md:p-12 w-full max-w-lg">
          {/* Placeholder for logo */}
          <div className="flex justify-center mb-8">
          <NavLink to="/home" onClick={() => {
                trackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: 'Logo Click'
                });
              }}>
              <img
                  src={phdscanner}
                  alt="Logo"
                  className="h-14 w-auto object-contain mr-3"
              />
            </NavLink>
          </div>

          {/* Placeholder for welcome text */}
          <div className="text-center mb-8">
            <p className="text-gray-600 mt-2">Sign in to continue</p>
          </div>

          <button
              onClick={handleGoogleSignIn}
              className="flex items-center justify-center w-full px-4 py-3 border border-gray-300 rounded-md shadow-md bg-white text-gray-700 hover:bg-gray-50 transition duration-300 transform hover:scale-105"
          >
            <img src={googleLogo} alt="Google Logo" className="h-6 w-6 mr-3" />
            <span className="text-lg font-medium">Sign in with Google</span>
          </button>
        </div>
      </div>
  );
}