// utils/opportunityUrlUtils.ts
export const createReadableOpportunityUrl = (
    id: string, 
    country: string,
    university: string, 
    title: string
  ): string => {
    const urlFriendlyString = `${country} ${university} ${title}`
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')  // Remove non-alphanumeric characters
      .trim()
      .replace(/\s+/g, '-')  // Replace spaces with hyphens
      .replace(/-+/g, '-');   // Remove consecutive hyphens
    return `${urlFriendlyString}-${id}`;
  };
  
  export const extractOpportunityId = (titleWithId: string): string => {
    // Regular expression to match a UUID
    const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const match = titleWithId.match(uuidRegex);
    
    // If a match is found, return the UUID; otherwise, return an empty string or handle the error as needed
    return match ? match[0] : '';
};
  
  export const isReadableUrl = (input: string): boolean => {
    // Check if the input contains a UUID-like ID at the end
    return /^[a-z0-9-]+-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(input);
  };