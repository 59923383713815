import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaGraduationCap, FaUniversity, FaGlobe, FaChartLine } from "react-icons/fa";
import { Link } from "react-router-dom";
import { countryImages } from "../../utils/countryImages";
import OpportunityGrid from "../OpportunityGrid/OpportunityGrid";
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import SubscribeEmail from "../SubscribeEmail/SubscribeEmail";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";
import { AuthContext } from "../../context/AuthContext";
import { useMutations } from "../../hooks/useMutations";
import useAnalytics from '../../hooks/useAnalytics';
import phdscannerBrand from "../../assets/phdscanner_brand.webp";

export default function Home() {
  const [finalSavedOpportunities, setSavedOpportunities] = useState(new Set());
  const { userDetails: user } = useContext(AuthContext);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  const {
    data: featuredOpportunities = [],
    error: featuredOpperror,
    isLoading: featuredOpportunitiesLoading,
  } = useQuery(
    ["opportunity countries count", "featured opportunities"],
    () => OpportunityService.getAllOpportunities({
      category: "featured",
      limit: 9,
    }),
  );


  const {
    data: data = [],
    error: oppbycntryError,
    isLoading: loadingSavedOppByCntry,
  } = useQuery(
    ["getOpportunityCountriesCount"],
    OpportunityService.getOpportunityCountriesCount,
  );

  // Conditionally call getSavedOpportunities only if user is logged in
  const {
    data: savedOpportunities = [],
    error: savedOppError,
    isLoading: loadingSavedOpp,
    isSuccess: savedOppSuccess,
  } = useQuery(
    ["getSavedOpportunities", user?.id], // Include user ID in the query key
    () => OpportunityService.getSavedOpportunities({ userId: user.id }), // Pass user ID to the service
    {
      enabled: !!user, // Only run this query if user is logged in
    }
  );


  // Convert the fetched data to a Set
  useEffect(() => {
    // Safely extract the array from savedOpportunities object
    const opportunitiesArray = Array.isArray(savedOpportunities?.savedOpportunities)
      ? savedOpportunities.savedOpportunities
      : [];

    if (savedOppSuccess && opportunitiesArray.length > 0) {
      setSavedOpportunities(new Set(opportunitiesArray));
    }
  }, [savedOpportunities, savedOppSuccess]);


  const { mutateAsync: createSaveOpportunity } = useMutations(
    OpportunityService.createSavedOpportunity
  );

  const { mutateAsync: deleteSaveOpportunity } = useMutations(
    OpportunityService.deleteSavedOpportunities
  );

  const opportunitiesByCountry = useMemo(
    () =>
      data?.map?.((item) => ({
        ...item,
        image_url: countryImages[item.Country] || "", // Map country to image URL
      })) || [],
    [data],
  );

  // Toggle save/unsave opportunities
  const toggleSave = (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    setSavedOpportunities((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(opportunityId)) {
        newSet.delete(opportunityId);
      } else {
        newSet.add(opportunityId);
      }
      return newSet;
    });
  };

  const {
    data: universitiesData = {},
    error: universitiesError,
    isLoading: loadingUniversities,
  } = useQuery(
    ["getOpportunityUniversitiesCounts"],
    OpportunityService.getOpportunityUniversitiesCounts,
  );

  // Create a memoized version of universities data
  const universitiesByCountry = useMemo(() => {
    return Object.entries(universitiesData).map(([country, universities]) => ({
      country,
      universities: universities.map(uni => ({
        ...uni,
        image_url: countryImages[country] || "", // Map country to image URL
      }))
    }));
  }, [universitiesData]);

  const handleToggleSave = async (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    try {
      if (finalSavedOpportunities.has(opportunityId)) {
        await deleteSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
      } else {
        trackEvent({
          category: 'Button',
          action: 'Click',
          label: 'User Opportunity Save'
        });

        await createSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="bg-[#FFEEED]">
      <SEOMetadata
        title="Explore Global PhD Opportunities | PhDScanner - Positions, Programs & Research Projects"
        description="Find available phd opportunities and positions here. Explore PhD Opportunities Worldwide"
        keywords="phdscanner, phd, phd postions, phd opportunities, phd openings, academic researchers, higher education, phd vacancies, phd jobs"
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url="https://www.phdscanner.com/about"
      />

      <section className="w-full px-2 md:px-8">
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={phdscannerBrand}
            type="image/webp"
          />
          <source
            media="(min-width: 769px)"
            srcSet={phdscannerBrand}
            type="image/webp"
          />
          <img
            src={phdscannerBrand}
            alt="PhD Opportunities Worldwide"
            className="w-full h-auto object-cover"
            loading="lazy"
          />
        </picture>
      </section>

      <section
        className="relative w-full px-2 min-h-screen flex items-center justify-center overflow-hidden"
        style={{ background: `linear-gradient(135deg, #EEA0ED 0%, #5AC1E4 100%)` }}
        aria-label="Academic Opportunities Hero Section"
      >
        <div className="relative z-10 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="flex flex-col items-center space-y-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-[#1B222A] leading-tight">
              <span className="block transform transition-transform hover:scale-[1.02] duration-300 mb-2">
                Discover Your Next
              </span>
              <span className="block text-[#DB4F40]">
                Academic Adventure
              </span>
            </h1>

            <p className="text-lg sm:text-xl lg:text-2xl text-[#1B222A] max-w-3xl leading-relaxed">
              Your gateway to top-tier academic roles and groundbreaking PhD opportunities worldwide.
            </p>

            <div className="flex flex-col sm:flex-row gap-4 mt-8">
              <Link
                to="/opportunity?category=standard"
                className="inline-block px-8 py-4 bg-[#DB4F40] hover:bg-[#EEA0ED] text-white rounded-full font-semibold 
                transform transition-all duration-300 hover:scale-105 hover:shadow-xl 
                focus:outline-none focus:ring-4 focus:ring-[#DB4F40]/50"
                onClick={() => {
                  trackEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'View All Opportunities from Home'
                  });
                }}
              >
                View All Opportunities
              </Link>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-16 w-full max-w-6xl">
              {[
                {
                  icon: FaUniversity,
                  number: "60+",
                  label: "Universities",
                  color: "text-blue-500"
                },
                {
                  icon: FaGraduationCap,
                  number: "2K+",
                  label: "PhD Positions",
                  color: "text-green-500"
                },
                {
                  icon: FaGlobe,
                  number: "9+",
                  label: "Countries",
                  color: "text-purple-500"
                },
                {
                  icon: FaChartLine,
                  number: "10K+",
                  label: "Active Researchers",
                  color: "text-indigo-500"
                }
              ].map((stat, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-xl p-6 text-center transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
                >
                  <stat.icon className={`mx-auto text-4xl mb-3 ${stat.color}`} />
                  <div className="text-3xl sm:text-4xl font-bold text-[#DB4F40] mb-2">{stat.number}</div>
                  <div className="text-[#1B222A] text-sm sm:text-base">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <svg
          className="absolute bottom-0 left-0 right-0 text-[#FFEEED]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 120"
          preserveAspectRatio="none"
        >
          <path
            fill="currentColor"
            d="M0,0l80,13.3C160,27,320,53,480,61.3C640,69,800,59,960,53.3C1120,48,1280,48,1360,48l80,0v72H0V0z"
          />
        </svg>
      </section>
      {/* Explore PhD Opportunities Worldwide Section (Modified) */}
      <section className="py-16 px-2 relative bg-[#FFEEED]">
        <div className="container mx-auto px-6 relative z-10">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-extrabold text-[#1B222A] leading-tight mb-4">
              Explore PhD Opportunities Worldwide
            </h2>
            <p className="text-xl text-[#1B222A] max-w-2xl mx-auto mb-8 leading-relaxed">
              Discover prestigious academic roles and groundbreaking research opportunities across the globe.
            </p>
            <div className="flex justify-center space-x-4">
              <Link
                to="/opportunity?category=standard"
                className="inline-block px-8 py-4 bg-[#DB4F40] hover:bg-[#EEA0ED] text-white rounded-full font-semibold 
                transform transition-all duration-300 hover:scale-105 hover:shadow-xl 
                focus:outline-none focus:ring-4 focus:ring-[#DB4F40]/50"
                onClick={() => {
                  trackEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'View All Opportunities from Home'
                  });
                }}
              >
                View All Opportunities
              </Link>
            </div>
          </div>

          {/* Opportunity by Country section remains the same */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {opportunitiesByCountry.slice(0, 9).map((opportunity, index) => {
              const encodedCountry = encodeURIComponent(
                JSON.stringify({ in: [opportunity.Country] }),
              );
              return (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-xl overflow-hidden 
                  transform transition-all duration-300 hover:scale-105 hover:shadow-2xl 
                  border border-gray-100"
                >
                  {/* Rest of the country card remains the same */}
                  <Link
                    to={`/opportunities?country=${encodedCountry}`}
                    onClick={(e) => {
                      e.preventDefault();
                      trackEvent({
                        category: 'Opportunity',
                        action: 'View',
                        label: 'Opportunity by country'
                      });
                      window.location.href = `/opportunity?country=${encodedCountry}`;
                    }}
                  >
                    <div className="relative">
                      <img
                        className="w-full h-56 object-cover transition duration-500 ease-in-out transform hover:scale-110"
                        src={opportunity.image_url}
                        alt={opportunity.Country}
                      />
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40"></div>
                    </div>
                    <div className="p-6 -mt-16 relative z-10">
                      <h3 className="text-2xl font-bold mb-4 text-white drop-shadow-lg text-center">
                        {opportunity.Country}
                      </h3>
                      <div className="flex items-center justify-center">
                        <div className="bg-white/80 backdrop-blur-sm rounded-full px-6 py-3 flex items-center">
                          <FaGraduationCap className="text-[#DB4F40] text-2xl mr-3" />
                          <p className="text-lg text-[#1B222A] font-semibold">
                            Opportunities: <span className="font-bold text-[#DB4F40]">{opportunity.Count}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/*Opportunity by university */}
      <section className="py-16 px-2" style={{ background: `linear-gradient(135deg, #EEA0ED 0%, #5AC1E4 100%)` }}>
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-extrabold text-[#1B222A] leading-tight mb-4">
              Universities by Country
            </h2>
            <p className="text-xl text-[#1B222A] max-w-2xl mx-auto mb-8 leading-relaxed">
              Explore PhD opportunities across top universities worldwide
            </p>
          </div>

          {loadingUniversities ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#DB4F40]"></div>
            </div>
          ) : (
            <div className="space-y-8">
              {Object.entries(universitiesData).map(([country, universities], countryIndex) => (
                <div key={countryIndex} className="bg-white/20 backdrop-blur-sm rounded-xl p-4">
                  <h3 className="text-2xl font-bold mb-4 text-[#1B222A] border-b pb-2 border-gray-300 flex items-center">
                    <span className="mr-3">🌍</span>
                    {country}
                    <span className="ml-auto text-sm text-[#DB4F40] font-medium">
                      {universities.length} Universities
                    </span>
                  </h3>
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3">
                    {universities.map((uni, uniIndex) => {
                      const encodedUniversity = encodeURIComponent(JSON.stringify({
                        in: [uni.universityName]
                      }));
                      const encodedCountry = encodeURIComponent(JSON.stringify({
                        in: [country]
                      }));

                      return (
                        <div
                          key={uniIndex}
                          className="bg-white shadow-md rounded-lg p-3 
                    hover:shadow-lg transition-all duration-300 
                    transform hover:-translate-y-1 
                    flex flex-col justify-between"
                        >
                          <div>
                            <h4 className="font-semibold text-[#1B222A] mb-2 text-sm truncate">
                              {uni.universityName}
                            </h4>
                            <div className="flex items-center">
                              <span className="text-[#DB4F40] font-bold bg-[#DB4F40]/10 px-2 py-0.5 rounded-full text-xs">
                                {uni.count} PhD Positions
                              </span>
                            </div>
                          </div>
                          <Link
                            to={`/opportunity?category=standard&university=${encodedUniversity}&country=${encodedCountry}`}
                            className="text-[#DB4F40] hover:underline text-xs mt-2 self-start"
                            onClick={() => {
                              trackEvent({
                                category: 'Link',
                                action: 'Click',
                                label: `View Opportunities by University university=${encodedUniversity} country=${encodedCountry}`
                              });
                            }}
                          >
                            View Details
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* Featured Opportunity Section */}
      <section className="py-16" px-2 style={{ background: `linear-gradient(135deg, #EEA0ED 0%, #5AC1E4 100%)` }}>
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-extrabold text-[#1B222A] leading-tight mb-4">
              Featured Opportunities
            </h2>
            <p className="text-xl text-[#1B222A] max-w-2xl mx-auto mb-8 leading-relaxed">
              Curated selection of exceptional academic roles and research positions.
            </p>
          </div>

          {featuredOpportunitiesLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#DB4F40]"></div>
            </div>
          ) : featuredOpportunities.length > 0 ? (
            <OpportunityGrid
              opportunities={featuredOpportunities}
              user={user}
              savedOpportunities={finalSavedOpportunities}
              handleToggleSave={handleToggleSave}
            />
          ) : (
            <p className="text-center text-[#1B222A] text-lg">
              No featured opportunities available at the moment.
            </p>
          )}
        </div>
      </section>

      <SubscribeEmail />
    </div>
  );
}
