import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaBuilding,
  FaUser,
  FaCalendarAlt,
  FaHeart,
  FaRegHeart,
  FaCheckCircle,
  FaInfoCircle,
} from "react-icons/fa";

import useAnalytics from '../../hooks/useAnalytics';
import { OpportunityService } from "../../services/OpportunityService";

// Tooltip component for icons
const IconWithTooltip = ({ icon: Icon, label, color, className, position }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Icon className={`${color} ${className || "h-5 w-5"}`} />
      </div>
      {showTooltip && (
        <div
          className={`absolute ${position === "right" ? "right-full" : "left-full"} ml-2 top-1/2 transform -translate-y-1/2 bg-[#1B222A] text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10`}
        >
          {label}
        </div>
      )}
    </div>
  );
};

// Opportunity card component
const OpportunityCard = ({
  opportunity,
  user,
  savedOpportunities,
  handleToggleSave,
}) => {
  const [imgError, setImgError] = useState(false);
  const navigate = useNavigate();
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  useEffect(() => {
    if (opportunity && opportunity.university) {
      const img = new Image();
      img.src = `./university/${opportunity.university}.png`;
      img.onload = () => setImgError(false);
      img.onerror = () => setImgError(true);
    }
  }, [opportunity]);

  const opportunityLink = OpportunityService.generateOpportunityLink(opportunity);
  const handleClick = () => {
    trackEvent({
      category: "Opportunity",
      action: "View Opportunity Details",
      label: opportunity.title,
    });
    navigate(`/opportunities/${opportunityLink}`);
  };

  const handleSaveToggle = () => {
    if (user) {
      handleToggleSave(opportunity.id);
      trackEvent({
        category: "Opportunity",
        action: savedOpportunities.has(opportunity.id) ? "Unsave Opportunity" : "Save Opportunity",
        label: opportunity.title,
      });
    }
  };

  if (!opportunity) {
    return (
      <div className="bg-[#FFEEED] rounded-xl shadow-md p-6 flex items-center justify-center h-full">
        <p className="text-[#1B222A]">Opportunity information not available</p>
      </div>
    );
  }

  const {
    id,
    university,
    title,
    department,
    supervisor,
    supervisor_url,
    closing_date,
    open_to,
    city,
    country,
    opportunity_url,
    fully_funded,
  } = opportunity;

  return (
    <div className="bg-white rounded-xl shadow-lg transition-transform duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col h-full border border-gray-100">
      <div className="p-6 flex-grow">
        <div className="flex items-center justify-between mb-4">
          <div className="flex-1 mr-4">
            {!imgError ? (
              <img
                src={`./university/${university}.png`}
                alt={university}
                className="h-24 object-contain transition-opacity duration-300 hover:opacity-80"
              />
            ) : (
              <a
                href={opportunity.university_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xl font-bold text-[#1B222A] hover:text-[#DB4F40] transition-colors duration-300 group"
              >
                {university}
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#DB4F40]"></span>
              </a>
            )}
          </div>
          <div className="flex-shrink-0 relative">
            <button
              onClick={handleSaveToggle}
              className={`text-[#DB4F40] hover:text-[#EEA0ED] transition-colors duration-300 ${!user ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={!user}
            >
              <IconWithTooltip
                icon={savedOpportunities.has(id) ? FaHeart : FaRegHeart}
                label={
                  user
                    ? savedOpportunities.has(id)
                      ? "Unsave Opportunity"
                      : "Save Opportunity"
                    : "Login to save"
                }
                color=""
                className="h-8 w-8"
                position="left"
              />
            </button>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          {open_to && (
            <div className="px-3 py-1 bg-[#EEA0ED]/20 text-[#1B222A] rounded-full text-sm font-medium flex items-center transition-all duration-300 hover:bg-[#EEA0ED]/30">
              {open_to}
              <IconWithTooltip
                icon={FaInfoCircle}
                label={`Open to ${open_to}`}
                color="text-[#1B222A] ml-1"
                className="h-4 w-4"
              />
            </div>
          )}
          {fully_funded && (
            <div className="px-3 py-1 bg-[#5AC1E4]/20 text-[#1B222A] rounded-full text-sm font-medium flex items-center transition-all duration-300 hover:bg-[#5AC1E4]/30">
              <FaCheckCircle className="mr-2 text-[#DB4F40]" />
              Fully Funded
            </div>
          )}
        </div>

        <h3 className="text-lg font-semibold text-[#1B222A] mb-4">
          <span className="bg-left-bottom bg-no-repeat transition-[background-size] duration-300 hover:bg-[length:100%_2px]">
            {title}
          </span>
        </h3>

        <div className="space-y-3 text-sm text-[#1B222A]/70">
          {[
            {
              label: "Department",
              value: department,
              icon: FaBuilding,
              color: "text-[#5AC1E4]",
            },
            {
              label: "Supervisor",
              value: supervisor,
              url: supervisor_url,
              icon: FaUser,
              color: "text-[#DB4F40]",
            },
            {
              label: "Deadline", value: closing_date,
              icon: FaCalendarAlt,
              color: "text-[#DB4F40]",
            },
          ].map((item, index) => {
            if (!item.value || item.value === "0") {
              return null;
            }

            return (
              <div key={index} className="flex items-center mb-2">
                <IconWithTooltip
                  icon={item.icon}
                  label={item.label}
                  color={item.color}
                />
                <span className="ml-2">
                  {item.url ? (
                    <a href={item.url} className="text-[#DB4F40] hover:underline">
                      {item.value}
                    </a>
                  ) : (
                    item.label === "Deadline"
                      ? new Date(Number(item.value) * 1000).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        },
                      )
                      : item.value
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="px-6 py-4 bg-[#FFEEED] flex flex-col sm:flex-row items-start sm:items-center justify-between mt-auto">
        <div className="w-full sm:w-3/4 mb-2 sm:mb-0 sm:pr-4">
          <p className="font-semibold text-[#1B222A] truncate">{university}</p>
          <p className="text-sm text-[#1B222A]/70 truncate">
            {city}, {country}
          </p>
        </div>
        <div className="w-full sm:w-1/4 flex justify-start sm:justify-end">
          {opportunity_url && (
            <button
              onClick={handleClick}
              className="w-full sm:w-auto px-4 py-2 bg-[#DB4F40] hover:bg-[#EEA0ED] text-white font-bold rounded-md transition duration-300 text-sm text-center transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#DB4F40]/50 focus:ring-opacity-50"
            >
              Details
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

// Grid component for opportunities
const OpportunityGrid = ({
  opportunities,
  user,
  savedOpportunities,
  handleToggleSave,
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
    {opportunities.map((opportunity, idx) => (
      <OpportunityCard
        key={idx}
        opportunity={opportunity}
        user={user}
        savedOpportunities={savedOpportunities}
        handleToggleSave={handleToggleSave}
      />
    ))}
  </div>
);

export default OpportunityGrid;