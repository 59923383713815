import { phdScannerApiAxios } from "../lib/axios-interceptor";
import { handleResponse } from "../utils/handleResponse";
import { createUrl, transformParams } from "../utils/helpers";
import { extractOpportunityId, isReadableUrl, createReadableOpportunityUrl } from "../utils/opportunityUrlUtils";

type PARAMTYPE = Record<string, string | number | boolean>;
export const OpportunityService = {
  async getAllOpportunities(params: PARAMTYPE) {
    const url = createUrl("/api/opportunities", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },

  async getOpportunityCountries(params: PARAMTYPE) {
    const url = createUrl("/api/opportunities/countries", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },

  async getOpportunityCountryUni(params: PARAMTYPE) {
    const url = createUrl("/api/opportunities/countries/universities", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },
  async getOpportunityCountriesCount() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/opportunities/countries/counts"),
    );
  },

  async getOpportunityUniversitiesCounts() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/opportunities/universities/counts"),
    );
  },

  async getCountryOpportunityById(id: string) {
    return handleResponse(() =>
      phdScannerApiAxios.get(`/api/opportunities/countries/${id}`),
    );
  },

  async getOpportunityById(identifierInput: string) {
    // Determine if input is a readable URL or direct ID
    const id = isReadableUrl(identifierInput) 
      ? extractOpportunityId(identifierInput)
      : identifierInput;

    return handleResponse(() => phdScannerApiAxios.get(`/api/opportunities/${id}`));
  },

  async createOpportunity(reqData: any) {
    return handleResponse(() =>
      phdScannerApiAxios.post("/api/opportunities", reqData),
    );
  },
  async getSavedOpportunities() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/user/opportunity/save"),
    );
  },
  async createSavedOpportunity(reqData: any) {
    return handleResponse(() =>
        phdScannerApiAxios.post("/api/user/opportunity/save", reqData),
    );
  },
  async deleteSavedOpportunities(reqData: any) {
    return handleResponse(() =>
      phdScannerApiAxios.delete("/api/user/opportunity/save", {
        data: reqData, // Pass the request body here
      })
    );
  },
  // Method to generate a link for navigation
  generateOpportunityLink(opportunity: {
    id: string;
    country: string;
    university: string;
    title: string;
  }): string {
    return `phd-positions-${createReadableOpportunityUrl(
      opportunity.id, 
      opportunity.country,
      opportunity.university, 
      opportunity.title
    )}`;
  }
};
