import React from "react";
import phdscannerName from "../../assets/phdscanner_about.webp";
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import { FaGraduationCap, FaUniversity, FaEnvelope } from "react-icons/fa";

export default function About() {
  return (
    <div className="bg-[#FFEEED]">
      <SEOMetadata
        title="PhDScanner About Us Page"
        description="Welcome to PhDScanner! At PhDScanner, we are dedicated to connecting aspiring researchers with PhD opportunities from universities around the globe. Our platform serves as a comprehensive resource for students and professionals seeking to advance their academic careers through doctoral programs."
        keywords="phdscanner, phd, phd postions, phd opportunities, phd openings, academic researchers, higher education, phd vacancies, phd jobs"
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url="https://www.phdscanner.com/about"
      />
      
      <div 
        className="relative w-full px-2 min-h-screen flex items-center justify-center overflow-hidden"
        style={{ background: `linear-gradient(135deg, #EEA0ED 0%, #5AC1E4 100%)` }}
      >
        <div className="container mx-auto px-4 py-16 relative z-10">
          <div className="lg:flex items-center space-x-8">
            {/* Image Section */}
            <div className="lg:w-1/2 mb-8 lg:mb-0 flex items-center justify-center">
              <div className="group w-full max-w-[500px]">
                <img
                  src={phdscannerName}
                  alt="PhDScanner"
                  className="w-full h-auto object-contain rounded-2xl shadow-2xl 
                  transform transition-all duration-300 
                  group-hover:scale-105 group-hover:shadow-xl"
                />
              </div>
            </div>

            {/* Content Section */}
            <div className="lg:w-1/2">
              <div className="bg-white rounded-2xl p-8 shadow-2xl">
                <div className="space-y-6">
                  {/* Section Title */}
                  <h2 className="text-4xl font-extrabold text-[#1B222A] leading-tight mb-4">
                    About <span className="text-[#DB4F40]">PhDScanner</span>
                  </h2>

                  {/* Introduction */}
                  <p className="text-lg text-[#1B222A] leading-relaxed mb-4">
                    Welcome to <strong className="text-[#DB4F40]">PhDScanner</strong>, 
                    your gateway to global PhD opportunities. We are passionate about 
                    connecting ambitious researchers with transformative academic 
                    journeys across universities worldwide.
                  </p>

                  {/* Key Offerings */}
                  <div className="space-y-4">
                    <div className="flex items-start space-x-4">
                      <FaGraduationCap className="text-[#DB4F40] text-3xl mt-1 flex-shrink-0" />
                      <div>
                        <h3 className="text-xl font-bold text-[#1B222A] mb-2">
                          Global PhD Listings
                        </h3>
                        <p className="text-[#1B222A] opacity-80">
                          Explore a comprehensive database of PhD opportunities 
                          across diverse fields and international universities.
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start space-x-4">
                      <FaUniversity className="text-[#DB4F40] text-3xl mt-1 flex-shrink-0" />
                      <div>
                        <h3 className="text-xl font-bold text-[#1B222A] mb-2">
                          University Collaboration
                        </h3>
                        <p className="text-[#1B222A] opacity-80">
                          We partner with institutions to showcase their research 
                          opportunities and connect with top-tier global talent.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Contact Section */}
                  <div className="bg-[#EEA0ED]/10 p-6 rounded-xl mt-6">
                    <div className="flex items-center space-x-3 mb-4">
                      <FaEnvelope className="text-[#DB4F40] text-2xl" />
                      <h3 className="text-xl font-bold text-[#1B222A]">
                        Get In Touch
                      </h3>
                    </div>
                    <p className="text-[#1B222A] mb-4">
                      Have questions or want to collaborate? Reach out to us at{" "}
                      <a
                        href="mailto:admin@phdscanner.com"
                        className="text-[#DB4F40] font-bold hover:underline"
                      >
                        admin@phdscanner.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SVG Wave Bottom */}
        <svg
          className="absolute bottom-0 left-0 right-0 text-[#FFEEED]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 120"
          preserveAspectRatio="none"
        >
          <path
            fill="currentColor"
            d="M0,0l80,13.3C160,27,320,53,480,61.3C640,69,800,59,960,53.3C1120,48,1280,48,1360,48l80,0v72H0V0z"
          />
        </svg>
      </div>
    </div>
  );
}