
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { PHDScannerQueryClient } from "./query-client";
import { AppRoutes } from "./AppRoutes";
import { QueryClientProvider } from "react-query";
import { AuthContextProvider } from "./context/AuthContext";
import { SnackbarProvider } from "notistack";
import useAnalytics from './hooks/useAnalytics'; // Import the custom hook
import { HelmetProvider } from 'react-helmet-async';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';

const App = () => {
  const { trackPageView } = useAnalytics(GA_MEASUREMENT_ID); // Initialize the hook

  return <AppRoutes trackPageView={trackPageView} />; // Pass trackPageView to AppRoutes
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={PHDScannerQueryClient}>
    <BrowserRouter>
      <AuthContextProvider>
        <SnackbarProvider>
          <HelmetProvider>
            <App /> {/* Render the App component */}
          </HelmetProvider>
        </SnackbarProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </QueryClientProvider>
);