import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

export function useMutations<TData, TError = unknown, TVariables = void>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables>,
): UseMutationResult<TData, TError, TVariables> {
  const mutation = useMutation<TData, TError, TVariables>(mutationFn, {
    ...options,
  });

  return mutation;
}
