import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
  useQuery as useRQ,
} from "react-query";

export function useQuery<T>(
  queryKey: string[],
  queryFn: QueryFunction<T>,
  params: any,
  options: UseQueryOptions<T> = {},
): UseQueryResult<T> {
  const query = useRQ<T>(
    queryKey,
    async () => {
      try {
        const res = await queryFn(params);
        if ("error" in (res as any)) throw new Error((res as any)?.error);
        console.log(res);
        return res;
      } catch (error) {
        throw error; // Re-throw error to allow react-query to process it
      }
    },
    options,
  );

  return query;
}
