// src/AppRoutes.tsx
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import ContactUs from "./components/ContactUs/ContactUs";
import Login from "./components/Login/Login";
import User from "./components/User/User";
import { Opportunities } from "./components/Opportunity/Opportunity";
import { OpportunityForm } from "./components/OpportunityForm/OpportunityForm";
import Layout from "./Layout";
import OpportunityDetail from "./components/OpportunityDetail/OpportunityDetail";

interface AppRoutesProps {
  trackPageView: (path: string) => void; // Define the prop type
}

export const AppRoutes: React.FC<AppRoutesProps> = ({ trackPageView }) => {
  const location = useLocation();

  useEffect(() => {
    // Track page view
    trackPageView(location.pathname + location.search);
  }, [location, trackPageView]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="login" element={<Login />} />
        <Route path="admin" element={<AdminDashboard />} />
        <Route path="user" element={<User  />}>
          <Route path=":userid" element={<User  />} />
        </Route>
        <Route path="opportunity" element={<Opportunities />} />
        <Route path="post-jobs" element={<OpportunityForm />} />
        <Route path="opportunities/:title" element={<OpportunityDetail />} />
      </Route>
    </Routes>
  );
};