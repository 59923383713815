import React, { useState, useEffect, useRef, useContext } from "react";
import { ChevronDown } from "lucide-react";
import phdscanner from "../../assets/phdscanner_logo.webp";
import { useNavigate } from "react-router-dom";
import UserProfilePopup from "../UserProfilePopUp/UserProfilePopup";
import { AuthContext } from "../../context/AuthContext";
import useAnalytics from '../../hooks/useAnalytics';

export default function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isServicesMenuOpen, setServicesMenuOpen] = useState(false);
  const navigate = useNavigate();
  const servicesMenuRef = useRef(null);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  const {
    userDetails: user,
    isUserDetailsLoading: loading,
    handleLogout,
  } = useContext(AuthContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        servicesMenuRef.current &&
        !servicesMenuRef.current.contains(event.target)
      ) {
        setServicesMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    trackEvent({
      category: 'Menu',
      action: 'Toggle Mobile Menu',
      label: isMobileMenuOpen ? 'Closed' : 'Opened'
    });
  };

  const toggleServicesMenu = () => {
    setServicesMenuOpen(!isServicesMenuOpen);
    trackEvent({
      category: 'Menu',
      action: 'Toggle Services Menu',
      label: isServicesMenuOpen ? 'Closed' : 'Opened'
    });
  };

  const handleSignIn = () => {
    trackEvent({
      category: 'User',
      action: 'Sign In',
      label: 'User clicked Sign In button'
    });
    navigate("/login");
  };

  return (
    <>
      <header
        className="fixed top-0 left-0 w-full z-50 shadow-md border-b border-gray-300"
        style={{ backgroundColor: '#FFEEED' }}
      >
        <nav className="px-4 lg:px-6 py-3" style={{ fontFamily: 'Sharpie, sans-serif' }}>
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a href="/home" className="flex items-center">
              <img
                src={phdscanner}
                className="h-14 w-auto object-contain lg:h-14 h-10" // Responsive height
                alt="Logo"
              />
              <span
                className="ml-3 text-2xl lg:text-2xl text-xl font-bold" // Responsive text size
                style={{
                  fontFamily: 'Grocetask, sans-serif',
                  color: 'black'
                }}
              >
                PhDScanner
              </span>
            </a>

            <div className="flex items-center lg:order-2">
              {!loading && (
                <div className="flex items-center">
                  {user ? (
                    <UserProfilePopup user={user} handleLogout={handleLogout} />
                  ) : (
                    <button
                    className="bg-[#DB4F40] hover:bg-[#C64537] text-white text-sm font-medium tracking-wide px-6 py-2.5 rounded-lg shadow-sm transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#DB4F40] focus:ring-opacity-50 hover:shadow-md"


                      onClick={handleSignIn}
                    >
                      Sign In
                    </button>
                  )}
                </div>
              )}
              <button
                type="button"
                className="inline-flex items-center p-2 ml-2 text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 transition-colors duration-200"
                onClick={toggleMobileMenu}
              >
                {/* SVG icons for mobile menu toggle */}
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : "block"}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className={`w-6 h-6 ${isMobileMenuOpen ? "block" : "hidden"}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className={`lg:flex lg:w-auto lg:order-1 w-full ${isMobileMenuOpen ? "block" : "hidden"}`}
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <a
                    href="/"
                    className={`block py-2 pr-4 pl-3 text-base font-medium tracking-wide rounded lg:p-0 transition-all duration-300 ${window.location.pathname === "/"
                      ? "text-blue-600 lg:text-blue-600 font-semibold"
                      : "text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-blue-600"
                      }`}
                      style={{
                        fontFamily: 'Grocetask, sans-serif',
                        color: 'black'
                      }}
                  >
                    Home
                  </a>
                </li>
                <li className="relative" ref={servicesMenuRef}>
                  <button
                    onClick={toggleServicesMenu}
                    style={{
                      fontFamily: 'Grocetask, sans-serif',
                      color: 'black'
                    }}
                    className="flex items-center py-2 pr-4 pl-3 w-full text-base font-medium tracking-wide text-gray-700 rounded hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-blue-600 lg:p-0 transition-all duration-300"
                  >
                    PhD Opportunity
                    <ChevronDown className="ml-1 h-4 w-4" />
                  </button>
                  {isServicesMenuOpen && (
                    <ul className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                      <li>
                        <a
                          href="/opportunity?category=standard"
                          className="block px-4 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                          style={{
                            fontFamily: 'Grocetask, sans-serif',
                            color: 'black'
                          }}
                        >
                          Explore PhD Opportunity
                        </a>
                      </li>
                      <li>
                        <a
                          href="/opportunity?category=featured"
                          className="block px-4 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                          style={{
                            fontFamily: 'Grocetask, sans-serif',
                            color: 'black'
                          }}
                        >
                          Featured PhD Opportunity
                        </a>
                      </li>
                    </ul>
                  )}
                </li>
                {["about", "contact"].map((item) => (
                  <li key={item}>
                    <a
                      href={`/${item}`}
                      className={`block py-2 pr-4 pl-3 text-base font-medium tracking-wide rounded lg:p-0 transition-all duration-300 ${window.location.pathname === `/${item}`
                        ? "text-blue-600 lg:text-blue-600 font-semibold"
                        : "text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-blue-600"
                        }`}
                        style={{
                          fontFamily: 'Grocetask, sans-serif',
                          color: 'black'
                        }}
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </a>
                  </li>
                ))}
                {user && user.email === "admin@phdscanner.com" && (
                  <li>
                    <a
                      href="/admin"
                      className={`block py-2 pr-4 pl-3 text-base font-medium tracking-wide rounded lg:p-0 transition-all duration-300 ${window.location.pathname === "/admin"
                        ? "text-blue-600 lg:text-blue-600 font-semibold"
                        : "text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-blue-600"
                        }`}
                    >
                      Admin
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <div className="h-20"></div>
    </>
  );
}