import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEOMetadata = ({
  title,
  description,
  keywords,
  imageUrl,
  url,
  type = 'website'
}) => {
  const linkedInCompany = {
    companyName: "PhDScanner",
    companyUrl: "https://www.linkedin.com/company/phdscanner/posts/?feedView=all",
    companyDescription: "PhDScanner - phd, phd openings, phd opportunity, phd positions, academic researchers "
  };

  return (
    <Helmet>
      {/* Standard SEO metadata */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph metadata */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {url && <meta property="og:url" content={url} />}

      {/* Twitter Card metadata */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}

      {/* LinkedIn Company Specific Metadata */}
      <meta property="og:site_name" content={linkedInCompany.companyName} />
      <meta property="og:see_also" content={linkedInCompany.companyUrl} />
      <meta property="og:description" content={linkedInCompany.companyDescription} />
    </Helmet>
  );
};

export default SEOMetadata;