import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { API } from "../constants";
import Cookies from "js-cookie";
export const phdScannerApiAxios = axios.create({
  baseURL: API,
  timeout: 120000,
  withCredentials: true,
});

async function addTokenInterceptor(
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> {
  const headers = config.headers || {};
  const accessToken = Cookies.get("token");
  const authToken = "";
  if (accessToken) headers["Authorization"] = `Bearer ${accessToken}`;
  if (accessToken) headers["X=Lamba-Auth"] = `Bearer ${authToken}`;
  return config;
}

phdScannerApiAxios.interceptors.request.use(addTokenInterceptor);
